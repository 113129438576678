.emotoional_back{
    width: 100%;
    height: 100%;
    padding: 5% 5%;
}

.moneyBack {
    background-color: #e4f5ff;
padding-top: 0px;
padding-bottom: 40px;
}

.bgYellowBtn {
    background-color: #ffe202;
    color: black;
    border: 0;
}

.em-btn {
margin-top: -170px;
padding-top: 10px;

}
@media (min-width: 340px) { 

    .em-font {
        font-size: 16px;
        
    }
    .em-btn{
        margin-top: -30px;
        
    }
    
    .btn-size{
        padding:7px 0px;
        width: 100%;
        margin-right: 10px;
    }
    .emotoional_back{
        width: 100%;
        height: 100%;
        padding: 0px;
    }

}


/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) { 

    .em-font {
        font-size: 14px;
        
    }
    
    .em-btn{
        margin-top: -30px;
    }
   
    .btn-size{
        padding:7px 0px;
        width: 100%;
        margin-right: 10px;
    }
 }

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .em-font {
        font-size: 18px;
        

    }
    .em-btn{
        margin-top:-70px;
    }
    
    .btn-size {
        padding: 10px 0px;
    }
   
  }

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 

    .em-btn{
        margin-top:-90px;
      }

    .btn-size{
        padding: 2%;
        
    }

    .em-font {
        font-size: 26px;

    }
  
 }

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
    .em-font {
        font-size: 25px;
    }
    .em-btn{
        margin-top:-50px;
    }
    .btn-size
    {
        width: 80%;
    }
   

 }

/* // XX-Large devices /(larger desktops, 1400px and up) */
@media (min-width: 1400px) {  
    .em-font {
        font-size: 30px;
    }
    .em-btn{
        margin-top:-50px;
    }

    .btn-size
    {
        width: 80%;
    }
 
}

.delCap {
    font-size: 1.4em;
    font-weight: 700;
    font-family: 'poppins';
}

.whyDel {
    background-color: #fff7d9;
}