.top{
    height: 50px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  
}

.tops{
    height: 70px;
    background-color: #ffe202;
}

.from{
    font-size: 14px;

}

.inputTxt{
    border: none;
    background-color: transparent;
    font-size: 0.9em;
    width: 100%;
}

.serch{
    background-color: white;
    border-radius: 10px;
    width: 90%;
    padding: 8px;
    display: flex;
    justify-content: space-between;
}

.barcode {
    position: absolute;
    right:0;
    transform: translate(-40%,0%);
}

.tops{
    height: 40px;
    /* background-color: #f8f0b4; */
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
}

.PDname{
    font-size: 0.9em;
    font-weight: 600;
    color: black;
    font-family: 'Lato';
    line-height: 16px;
   
}

.awb1{
    font-size: 0.8em;
    font-weight: 500;
    color: gray;
}

.pdType{
    font-size: 0.9em;
    background-color: #ffe202;
    border-radius: 5px;
    padding: 1px 10px

}

.deliver{
    font-size: 1em;

}

.priority{
    font-size: 0.85em;
    color: rbga(0,0,0,0.2);
}

.add{
    font-size: 0.85em;
}

.add1{
    font-size: 0.9em;

}

.detailss{
    background-color: #fff7d9;
    width: 100%;
    border-radius: 8px;
    line-height: 20px;
}

.ofp{
    background-color: #ffe202;
}

.text-size{
    font-size: 0.90em;
    padding: 4px 15px;
}

.topBorder{
    border: 1px solid black;
    border-radius: 5px;
    width: 100%;
    padding: 8px 8px;

}

.otp {
    font-size: 1em;
    color: black;
}

.otpInput{
    border: 1px solid black;
    margin: 4px 10px;
    color: black;
    width: 90px;
    border-radius: 5px;
    font-size: 1.2em;
}

.taskBtn {
    border: 1px solid #07634c;
    background-color: #07634c ;
    color: white;
}

.taskBtn1 {
    border: 1px solid #f2f2f2;
    background-color: #f2f2f2;
    color: gray;
}

.optionMen {
    width: 35px ;
    height: 35px;
    background-color: #f2f2f2;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;

}

.scrollable-div1 {
    height: 45vh;
    overflow: auto;
    text-align: justify;
  }

  .bgYellows {
    background-color: #ffe202 !important;
    border-radius: 10px;
    font-size: 1.1em;
    color: black !important;
    width: 85% !important;
  }
  
 
  