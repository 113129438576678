.gpsSize{
    height: 160px;
    width: 90vw;
}

.gpsBtn{
    background-color: #0d1740;
padding: 0px 10px;
    color: white;
    text-align: center;
    border-radius: 5px;
    font-family: 'Roboto';
}