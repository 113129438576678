.invFrm {
    background-color: #ffe202;
    padding: 2px 10px;
}

.invAds {
    font-size: 0.95em;
    font-family: 'Lato';
}

.toName {
    font-size: 0.95em;
    font-family: 'Lato';
    font-weight: 700;
}

.invCnt {
    font-size: 1.2em;
    font-family: 'Lato';
    font-weight: 700;
    line-height: 30px;
}

.InvWdith {
    width: 38%;
}

.ToWidth {
    width: 58%;
}

.invValue {
    font-size: 1.8em;
    font-family: 'Lato';
    font-weight: 700;
    color: #0d1740;
}
.curreny{
    background-color: #2df8c5;
    font-size: 0.85em;
    font-family: 'Lato';
    font-weight: 500;
}

.invDes {
    width: 60%;
}

.invQty {
    width: 13%;
    text-align: center;
}
.invPrice{
    width: 13%;
    text-align: center;
}
.invTotal {
    width: 13%;
    text-align: center;
}
.invHeader {
    background-color: #004246;
    font-family: 'Lato';
    font-size: 0.9em;
    font-weight: 700;
    border-radius: 4px;
    
}
.btnInv {
    background-color: black;
    color: white
}
.desHead {
    background-color: #ffe202;
    padding: 5px 3px;
}

.InvFAdd {
    font-size: 0.85em;
    font-family: 'Lato';
    font-weight: 400;
}

.invEdit {
    border-radius: 10px;
    border : 1px solid gray;
    padding: 10px 10px;
    width: 40%;
    height: 20%;
}

.invDel {
    border-radius: 10px;
    border : 1px solid #ae361c;
    padding: 10px 10px;
    width: 40%;
    height: 20%;
    background-color: #ae361c;
    color: white;
}

.InvHt {
    height: auto;
    overflow-y: auto;
     }