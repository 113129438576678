.under-border {
    
background: #F2F2F2;
border-radius: 30px;
padding: 2% 5%;

}

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
   padding-top: 10%;
  }

  .u-font{
      font-size: 13px;
  }
