.edit-btn{
    width: 50%;
    border: 1px solid gray;
    background-color: white;
    border-radius: 5px;
    font-size: 15px;
    font-family: 'Lato';
}

.delete-btn{
    width: 50%;
    border: 1px solid rgb(170, 4, 4);
    background-color: white;
    border-radius: 5px;
    font-size: 15px;
    font-family: 'Lato';
}