.rightIcon{

    right:0;
    height: 50px;
    width: 50px;
    background-color: black;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.rightIcon1{


    height: 50px;
    width: 50px;
    background-color: transparent;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.badge{
    font-size: 10px;
    position: absolute;
    top: 0;
    color: white;
    transform: translate(90%,-20%);
}