





.smart-space{
    margin-top: 4%;
}

.smart-back{
    background-color: #FFE500;
    border-radius: 10px;
    text-align: center;
    padding: 3px;
}

.desc-ser{
    font-size: 14px;
}

.del_boarder{
    border : 1px solid #ffe202;
    border-radius: 10px;
    padding:1% 10%;
    
}

.card-text{
    font-size: 14px;
}

.btn-outline-warning {
    color: #000 !important;
    border-radius: 50px !important;
}

.btn {
    border-radius: 50px !important;
}

@media (min-width: 356px) {

    .img-size-smart {
        height: auto;
        width:70%;
        padding:10%;  
    }
    .img-size-smart-1 {
        margin-top: 2%;
        height: 80%;
        width: 80%;
        margin-bottom: 10%;
        padding:5%;  
       
    }
    .img-size-smart-2 {
        margin-top: 6%;
        height: 80%;
        width: 80%;
        margin-bottom: 7%;
        margin-left:-5px;
        padding:10%;
        
    }

    .resSmart{
        padding-top: 40px;
        margin-top: 80px;
    }
   
 }




@media (min-width: 576px) {

    .img-size-smart {
        height: auto;
        width:80%;
        padding:10%; 
         
    }
    .img-size-smart-1 {
        margin-top: 2%;
        height: 80%;
        width: 80%;
        margin-bottom: 10%;
        padding:5%;  
       
    }
    .img-size-smart-2 {
        margin-top: 6%;
        height: 80%;
        width: 80%;
        margin-bottom: 7%;
        margin-left:-5px;
        padding:10%;
        
    }

    .resSmart{
        padding-top: 100px;
    }

 }

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 

    .smart-font{
        font-size: 12px;
        padding-left: 8px;  
    }

    .img-size-smart {
        height: auto;
        width: 73%;
        padding:5%;  
    }

    .img-size-smart-1 {
        margin-top: 7%;
        height: 100%;
        width: 220px;
        margin-bottom: 6%;
        margin-left:-5px;
        
    }

    .img-size-smart-2 {
        margin-top: 7%;
        height: 100%;
        width: 100%;
        margin-bottom: 1%;
      
        padding:10%;
        
    }
    .desc-ser{
        font-size: 12px;
    }

    .resSmart{
        padding-top: 80px;
    }
 }

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 

    .img-size-smart {
        height: auto;
        width: 73%;
        padding:5%;  
    }

    .smart-font{
        font-size: 12px;
        padding-left: 8px;  
    }

    .img-size-smart-1 {
        margin-top: 7%;
        height: 100%;
        width: 100%;
        margin-bottom: 7%;
        margin-left:-5px;
        
    }

    .img-size-smart-2 {
        margin-top: 7%;
        height: 100%;
        width: 100%;
        margin-bottom: 6%;
      
        padding:10%;
        
    }
    .desc-ser{
        font-size: 12px;
    }
    .resSmart{
        padding-top: 20px;
    }
 }

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
    .smart-font{
        font-size: 14px;
        padding-left: 10px;
    
    }

    .img-size-smart {
        height: auto;
        width: 73%;
        padding:10%; 
    }
    .img-size-smart-1 {
        margin-top: 2%;
        height: 100%;
        width: 100%;
        margin-bottom: 10%;
        padding:5%;  
       
    }
    .img-size-smart-2 {
        margin-top: 5%;
        height: 100%;
        width: 100%;
        margin-bottom: 7%;
        margin-left:-5px;
        padding:10%;
        
    }
    .desc-ser{
        font-size: 14px;
    }

    .resSmart{
        padding-top: 20px;
    }
 }

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) { 

    .img-size-smart {
        height: auto;
        width: 100%;
        padding:10%;  
    }
    .img-size-smart-1 {
        margin-top: 2%;
        height: 100%;
        width: 100%;
        margin-bottom: 10%;
        padding:5%;  
       
    }
    .img-size-smart-2 {
        margin-top: 5%;
        height: 100%;
        width: 100%;
        margin-bottom: 7%;
        margin-left:-5px;
        padding:10%;
        
    }
    .desc-ser{
        font-size: 14px;
    }

    .service-font{
        font-size: 20px;
    }

    .resSmart{
        padding-top: 20px;
        padding-bottom: 30px;
    }

    
 }