.TracCon {
    background-color: #ffe202;
    border-radius: 0px 0px 20px 20px;
    margin-top: 50px;
}

.trcBnd {
    background-color: white;
    width: 95%;
    padding: 8px 10px;
    border-radius: 10px;
    
}

.recPar {
    font-size: 1.0em;
    font-family: 'Lato';
    border: 20px;
    background-color: #ff0000;
}

.parSta {
    background-color: #e4f5ff;
    padding: 10px;
    border-radius: 20px;
    width: 45px;
    height: 45px;
}




.trackID {
    font-size: 1.0em;
    font-family: 'Lato';
    font-weight: 700;
}

.statusPAr {
    font-size: 0.85em;
    font-family: 'Lato';
    line-height: 15px;
}

.scrollable-div {
    /* height: 55vh; */
    overflow: auto;
    text-align: justify;
  }

  .TrcEr {
    color: #ff0000;
    font-size: 0.85em;
    font-family: 'Lato';
    font-weight: 700;
  }

 

  .ShipChg {
    background-color: #e4f5ff;
    border-radius: 5px;
  }

  .bgYel {
    background-color: #ffe202;
  }

  .trackHis {
    
    border-radius: 15px;
  }

  .lblCon {
    background-color: white;
    padding: 10px 20px;
    border-radius: 10px;
  }

  .lblRnd {
    background-color: rgba(128, 128, 128, 0.379);
    padding: 5px ;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    font-size: 1em;
    font-family: 'Roboto';
    font-weight: 500;
    letter-spacing: 0.05em;
  }