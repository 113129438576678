.heading{
    
font-size: 20px;
/* border-bottom: 5px solid #FFE202;
padding: 6px;
margin: 0 0 -1px;
display: inline-block; 
margin-top: 4%; */
font-weight: 900;
}



@media (min-width: 340px) { 
    .e-heading{
    
        font-size: 1.2em;
        border-bottom: 3px solid #FFE202;
        padding: 0px;
        margin: 0 0 -1px;
        display: inline-block; 
        margin-top: 10%;

        }
        .desc {
            font-size:  0.8em ;
        }
        
        
   }
  
  
  @media (min-width: 576px) { 
    .e-heading{
    
        font-size: 1.2em;
        border-bottom: 3px solid #FFE202;
        padding: 6px;
        margin: 0 0 -1px;
        display: inline-block; 
        margin-top: 10%;

        }
        .desc {
            font-size:  0.9em ;
        }  
        
   }
  
  
  @media (min-width: 768px) { 
    .e-heading{
    
        font-size: 1.4em;
        border-bottom: 5px solid #FFE202;
        padding: 6px;
        margin: 0 0 -1px;
        display: inline-block; 
        margin-top: 10%;

        }
   }
  
  
  @media (min-width: 992px) { 
  
    .e-heading{
    
        font-size: 1.6em;
        border-bottom: 5px solid #FFE202;
        padding: 6px;
        margin: 0 0 -1px;
        display: inline-block; 
        margin-top: 3%;

        }
  }
  
  @media (min-width: 1200px) { 
    .e-heading{
    border-radius: 10px;
    bottom: 0px;
    margin-top: 3%;
}

        .desc {
            font-size:  17px ;
        }  
   }