.destination1 {
    display: flex;
    background-color: #eeeeee;
    padding:20px;
    
}

.SearchBarStyle {
     padding:3px 6px;
     background-color: white;
     display: flex;
     flex-direction: row;
     border-radius: 20px;
     width: 100%;
    
     align-items: center;
}

.SearchBarStyle input {
    border: none;
    width: 100%;
}

.SearchFont {
    font-size: 13px;
}
.DestFnt {
    font-size: 14px;
    padding:10px 20px; 
}

.dest{
    overflow-y: auto;
}

.searchInput{
    border: none;
    width: 100%;
    font-size: 14px;
}

.listZip{
    font-family: 'Lato';
    font-size: 13px;
}