.Location{
    height: 100%;
    width: 100%;
}
.curBack{
    background: black;
    mix-blend-mode: hard-light;
    box-shadow: 0px 4px 4px rgba(206, 199, 199, 0.25);
   width: 28px;
   height: 28px;
   border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  margin-left: 10px;
  cursor: pointer;
  padding:5px 5px;
}
.LocCard{
    padding:20px;
    display: flex;
    flex-direction: column;
    position: absolute;
    height: 180px;
}
.ShortName{
font-size: 20px;
font-family: 'Lato','Roboto';
font-weight: 700;
}

.locDetail{
   font-size: 14px;
   font-weight: 400;
   color: gray;
   font-family: 'Lato','Roboto';
}

.saveAs{
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 3px 12px;
    justify-content: space-between;
}

.Activebtn{
    background-color: white;
    cursor: pointer; 
    border: 1px solid black ;
    border-radius: 4px;

}

.Savebtn{
    background-color: white;
    border: 1px solid rgba(151, 151, 151, 0.4);
    cursor: pointer; 
    /* border: 1px solid black ; */
    border-radius: 4px;

}


.SaveFnt {
    font-size: 14px;
    font-family: 'Lato','Roboto';
    
}

.senderCon{
    display: flex;
    justify-content: space-between;
  
}

.fromInput{
    background: #FFFFFF;

    border: 1px solid rgba(151, 151, 151, 0.4);
    box-sizing: border-box;
    border-radius: 10px;
    padding: 5px 10px;
    margin-bottom: 10px;
    width: 100%;
    color: #000;
}

  .locInput{
    box-sizing: border-box;
    border: none;
    font-family: 'Lato','Roboto';
    color :#000;
    width: 100%;
    border-bottom:  1px solid rgba(151, 151, 151, 0.5);
    
  }
  
  .marker-pos1{
    position: absolute;
    top:0;
   width: 100%;
   height: 50%;
   background-color: transparent;
    transform: translate(-2px,88%);
}

.inputErr{
    background: #FFFFFF;

    border: 1px solid rgb(170, 5, 5);
    box-sizing: border-box;
    border-radius: 10px;
    padding: 5px 10px;
    margin-bottom: 10px;
    width: 100%;
    color: #000;
}

.gps{
    /* background-color: #ffe202; */
    /* width: 45%; */
    position: absolute;
    top:0;
    margin-top: 8px;
    font-size: 1.2em;
    font-family: 'Lato';
    font-weight: 700;
}

.SaveLoc{
    position: absolute;
    bottom:0;
    width: 95%;
  transform: translate(-3%,0%);
}

.locLabel{
    font-size: 0.75em;
    font-weight: 400;
    font-family: 'Lato';

}

.floatLabel {
font-size: 0.93em;
color: gray;
font-weight: 300;
letter-spacing: 0.02em;
position: relative;
transform: translate(0%,-100%);
animation-duration: 0.5s;
animation-name: example;
animation-fill-mode: forwards;
}

@keyframes example {
    0%  {font-size: 0.2em;opacity: 0;transform: translate(0%,-50%)};
    100% {font-size: 0.9em;opacity: 1;display: block;}
}

.float-container input {
    border: none;
    outline: 0;
  }

  .tagSav{
      font-size: 0.85em;
    
  }
  
 .alertBtm{
     font-size: 0.73em;
     padding: 2px 12px;
     color: #f00;
 }

 .cancel{
     font-size: 0.9em;
     font-family: 'Lato','Roboto';
    color: #000;
    font-weight: 500;
    position: absolute;
    right:0;
    transform: translate(-12%,-170%);
 }

 .topMar{
padding-top: 15px;
 }


.cityFloatR {
    position: absolute;
    right: 0;
    top:0;
    transform: translate(0%,85%);
    font-family: 'Lato';
    font-size: 0.85em;
    color: #008000;
}

.locSearch {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius:5px ;
    border: 1px solid black;
    padding: 10px;
    padding-right: 2px;
}

.srcBtn {
    background-color: white;
    display: flex;
    justify-items: center;
    align-items: center;
    font-size: 0.75em;
    font-family: 'Roboto','Lato';
}

.srchPlaceholder {
    font-family: 'Roboto';
    font-size: 0.95em;
    color: rgba(0, 0, 0, 0.682);
}

.srchPlaceholder1 {
    font-family: 'Roboto';
    font-size: 1.2em;
    color: rgba(0, 0, 0, 0.682);
}

.pcBtn {
    width: 210px;
}



.pkCon {
    background-color: #f2f2f2;
    border-radius:  20px 20px 0px 0px;

}

.cardPk {
    padding: 5px;
    width: 100%;
    border-radius: 10px;
    background-color: #ffe202;
}

.cardPk1 {
    padding: 5px;
    width: 100%;
    border: 1px solid #ffe202;
    border-radius: 10px;
    background-color: white;
}

.fName2 {
    font-family: 'Roboto';
    font-size: 0.95em;
    font-weight: 700;
} 

.mrgTop {
    margin-top: 0px;
}

.srchLoc {
    border: 1px solid #000;
    padding: 3px;
    background-color: white;
}

.size-Pk {
    height: 100%;
    width: 90%;
}

@media (min-width: 375px) { 
.mrgTop {
    margin-top: 50px;
}
}

@media (min-height: 575.98px) { 
    .mrgTop {
        margin-top: 50px;
    }
}



/* // Small devices (landscape phones, 576px and up) */
@media (min-height: 576px) and (max-height: 767.98px) {
    .mrgTop {
        margin-top: 50px;
    }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-height: 768px) and (max-height: 991.98px) { 
  
}

/* // Large devices (desktops, 992px and up) */
@media (min-height: 992px) and (max-height: 1199.98px) { 

}

/* // Extra large devices (large desktops, 1200px and up) */

@media (min-height: 600px) {

}

@media (min-height: 667px) {

}

@media (min-height: 720px) {

}

@media (min-height: 800px) {

}

@media (min-height: 844px) {

}

@media (min-height: 851px) {
 
}

@media (min-height: 896px) {
 
}


@media (min-height: 914px) { 
   
}

@media (min-height: 1024px) {
 
}

@media (min-height: 1180px) {
   
}

@media (min-height: 1368px) {
   
}