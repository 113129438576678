.relx{
    width:100%;
    height:230px;
}
.relaxCon{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.offline{
    background-color: rgb(160, 4, 4);
    font-family: 'Roboto',serif;
}