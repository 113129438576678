.statusT{
    font-size: 0.9em;
}

.estHeader{
    background-color: #faef9f;
    border-radius: 5px;
    padding: 4px 5px;
    border : 1px solid #ffe202;
}

.wayBill{
    font-size: 0.7em;
  font-family: 'Lato','Roboto';
  color: rgb(71, 69, 69);
}

.prog{
   border-left: 1.2px solid rgba(0,0,0,0.2);
   height: auto;
   display: flex;
    margin-left: 15px;
   align-items: center;
}

.statuss{
    font-size: 0.9em;
    display: flex;
    flex-direction: column;
}

.prgStatus{
    font-size: 0.85em;

}
.dateTime{
    font-size: 0.89em;
}