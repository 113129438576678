.localBg {
    background-color: white;
}

.locSiz {
    width: 140%;
    height: 80%;
 
}

.locSiz1 {
    width: 160%;
    height: 80%;
 
}

.serTxt {
    color: #212936;
}

.indBg {
    background-color: #004247;
}

.btnShip {
    background-color: #FFE500 !important;
    border-color: #FFE500 !important;
    border-radius: 10px !important;
  font-size: 18px;
  font-family: 'Lato','Roboto';
  width: 140px;
  padding: 6px 8px;
}

.client {
    font-size: 0.95em;
}

.highlight {
    font-size: 1.3em;
    /* border-bottom: 5px solid #FFE202; */
    display: inline-block; 
    font-family: 'Poppins';
    margin-top: 1%;
    margin-bottom: 1%;
    font-weight: 600; 
    color: #004247;
    background-color: #e4f5ff;
}


@media (min-width: 376px) { 

    .locSiz {
        margin-top: 8%;
        width: 90%;
        height: 70%;
        
    }

    .locSiz1 {
        margin-top: 8%;
        width: 100%;
        height: 40%;
     } 

}

@media (max-width: 575.98px) { 
    .locSiz {
        margin-top: 15%;
        width: 100%;
        height: 80%;
    }
    .locSiz1 {
        margin-top: 12%;
        width: 100%;
        height: 70%;
     
    } 
 }

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
    .locSiz {
        margin-top: 15%;
        width: 100%;
        height: 80%;
    }

    .locSiz1 {
        margin-top: 8%;
        width: 100%;
        height: 80  %;
     
    } 
    
  }

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) { 
    .locSiz {
        width: 140%;
        height: 80%;
    }

    .locSiz1 {
        margin-top: 50%;
        width: 100%;
        height: 26%;
     
    } 
    
 }

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) { 
    .locSiz {
        width: 100%;
        height: 80%;
    }

    .locSiz1 {
        margin-top: 20%;
        width: 99%;
        height: 40%;
     
    } 
   
 }

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
    .locSiz {
        width: 100%;
        height: 80%;
    }

    .locSiz1 {
        margin-top: 8%;
        width: 100%;
        height: 40%;
     
    } 
 }


 .highB {
    background-color: #ffe202;
    margin-top:6px;
    width: 3px;
    height: 25px;
    padding: 3px;
 }

 .visBg {
    background-color: #ffe202;
    padding: 3px 5px;
    font-size: 0.95em;
    font-family: 'Roboto';
    color: black;
 }

 .bgLight {
    background-color: #FFFCE6;
 }

 .visCap {
    font-family: 'Poppins';
    font-size: 0.85em;

 }