.ht {
  height: 100vh; 
 
}

.accTop{
  padding-top: 110px;
}
.accHt {
  height: 100vh;
}

.MapHead1{
  position: absolute;
 height: 20px;
   top: 15px;
   width: 98%;
   padding:10px;
 
   display: flex;
   justify-content: space-between;
   align-items: center;
}