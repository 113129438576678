.localList {
    display: flex;
    flex-direction: column;
    padding: 0;
   
}

.LocationList {

    height: auto; 
   
    overflow:scroll;
    -webkit-overflow-scrolling: touch;
}

.locHead{
    font-size: 16px;
    font-family: 'Lato','Roboto';
    font-weight: 500;
    padding:0 !important;
    margin: 0px!important;
}

.desc {
    font-size: 13px;
    font-family: 'Lato','Roboto';
    padding: 0px !important;
    margin-top: -4px !important;  
    
    font-weight: 500;
}
.scroll-y{
    overflow-y: auto;
}

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #ffffff #ffffff;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 16px;
  }

  *::-webkit-scrollbar-track {
    background: #ffffff;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #ffffff;
    border-radius: 10px;
    border: 3px solid #ffffff;
  } 

  .locCon{
    cursor: pointer;
  }

  .mst {
    font-size: 0.7em;
    height: 30px;
  background-color: #f2f2f2;
  display: flex;
  justify-content:start;
  align-items: center;
  margin-bottom: 10px;
  padding: 2px 2px; 
  }