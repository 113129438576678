.addCon{
    display: flex;
    flex-direction: row;
    padding: 10px;
    justify-content: left;
    align-items: center;
  cursor: pointer;
  
}

.title{
    color: rgba(0, 0, 0, 0.9);
    font-size: 14px;
    font-weight: 500;
    font-size: "Roboto";
}

.addreess{
    display:flex;
    flex-direction: column;
    padding: 5px 0px;
    padding-left: 20px;
    padding-right: 12px;
    font-family: 'Lato','Roboto';
    width: 100%;
}

.addDetail {
    font-size: 14px;
    color: gray;
    font-family: 'Lato','Roboto';
    
}

.addDst{
    font-size: 13px;
    font-family: 'Lato','Roboto';
    color: rgba(0, 0, 0, 0.808);
}
.PersonDetail{
    display: flex;
    flex-direction: row;
   width: 100%;
   justify-content: space-between;
  
}

.PersonalFont {
    font-size: 12px;
    color: rgb(66, 64, 64);
    display: flex;
    flex-direction: row;
    justify-items: start;
    align-items: center;
    padding :2px 10px;
   
}

.personGap{
    padding: 2px 5px;
}
.divider{
    color: gray;
}

.activeLoc{
    position:absolute;
    top:0;
    right:0;
    background-color: white;
    
    height: 22px;
    width: 16px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 2px;
    transform: translate(40%,-14%);
}
