@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,500;1,500;1,600&display=swap');

.newAdd {
    display: flex;

}

.bottomBtn{
    position: absolute;
    bottom:0;
    width: 100%;
    padding-left: 10px;
    padding-bottom: 2px;
}

.city {
    padding-right: 33px;
    color: green;
  font-size:small;
  font-family: 'Poppins', sans-serif;
}


#spinnerr{
    height: 1.7rem;
    width: 1.7rem;
}
.fixedNew{
    position: absolute;
    top:0;
    transform: translate(0%,125%);
    background-color: white;
    width: 95%;
    z-index: 10;
   
}