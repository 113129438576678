
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap');

.ser-border {
    background: #fff;
border-radius: 30px;
width: 50px;
height: 52px;
padding:9px;
text-align: center;
display: inline-block;
cursor: pointer;
margin-bottom: 25px;
}

.act-border {
    background: #cdedf0;
border: 2px solid #004247;

border-radius: 30px;
width: 50px;
height: 52px;
padding:9px;
text-align: center;
display: inline-block;
cursor: pointer;
margin-bottom: 25px;
}

.icon-fnt {
    font-size: 12px;
    margin-top: 13px;
    font-family: 'Roboto',sans-serif;
    font-weight: 400;
}

.icon-size{
    padding: 0.14em;
}