.headTracking1{
    height: auto;
    width: 100%;
    background-color: white;
    
}

.estTIme{
    font-size: 1.2em;
    font-family: 'Lato';
    font-weight: 700;
}


.carrier{
    font-size: 0.95em;
    font-weight: 400;
    font-family: 'Lato';
}

.network{
    font-size: 1.2em;
    font-family: 'Lato';
    font-weight: 700;
}

.TranLine1 {
    height: 38px;
    width: 0.5px;
    background-color: rgba(128, 128, 128, 0.712);
    position: absolute;
    top:0;
    transform: translate(50%,62%);
}

.statusTran{
    background-color: #0d1740;
    border-radius: 50%;
    padding:9px;
    height:40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dateWidth{
    width: 100px;
}

.doorPik{
    font-size: 1.5em;
    font-weight: 700;
    font-family: 'Lato';
  
}

.cap{
    line-height: 18px;
    font-size: 0.95em;
    font-family: 'Roboto';
    text-align: center;
    font-weight: 500;
    padding: 10px 10px;
    
   }

.bBtn{
    background-color: #ffe202;
    font-size: 25px;
    width: 50%;
    border-radius: 10px;
    padding: 8px 10px;
}

.bgBlue{
    background-color: #0d1740;
    padding: 0px 4px;
    color: white;
    border-radius: 5px;
    font-family: 'Lato';
    font-weight: 700;
}

.fromTo{
    background-color: #e4f5ff;
    padding: 5px 0px;
    border-radius: 5px;
}

.orgLabel {
    font-size: 0.95em;
    font-family: 'Lato','Roboto';
    letter-spacing: 0.015em;
    font-weight: 700;
    /* opacity: 0.7; */
}


.zIndex {
    z-index:  1;
}

.statFnt {
    font-size: 1.25em;
    font-weight: 700;
    color:black;
    font-family: 'Roboto','Lato';
}

.dhlSize{
    height: 20px;
    width: 80px;
}

.fedexSizs{
    height: 20px;
    width: 80px;
}

.upsSize{
    height: 25px;
    width: 25px;
}

.img-Track{
    width: 40%;
    height: 40%;
}