.otp-text {
    font-size: 14px;
    color: gray;
}

.otp-border {
    background: white;
    border: 1px solid black;
/* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
border-radius: 10px;
padding: 2% 3%;
width: 13%;
}

.otp-input {
    width: 100%;
    border:0px;
    background-color: white;
    text-align: center;  
}
.send-font{
    font-size: 14px;
}

