.initBg {
    height: 100vh;
    background-color: #0D1740;
    display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
}

.splash1 {
    height: 150px;
    width: 90%;

    }

.splash {
    height: 100vh;
    width: 100%;
    position: absolute;
    }

    .servi{
        font-size: 0.75em;
        color: white;
        position: absolute;
        bottom:0;
    }

    .plsWaitt{
        position: absolute;
        top:0;
        left: 0;
        transform: translate(100%,500%);
    }