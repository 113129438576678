.drop-border {
    background: #FFFFFF;
border: 1px solid rgba(24, 23, 23, 0.3);
box-sizing: border-box;
border-radius: 5px;
padding: 5px 0px;
margin-left: 1%;
width: 100%;
margin:0px;
position: relative;
}

.WgtError {
    border: 2px solid darkred;
}

.labelWt{
    font-size: 0.7em;
    font-family: 'Lato' , 'Roboto';
    color: rgba(0,0,0,0.7);
}

.drop-style{
    border: 0;
    width: 50%;
    font-size: 12px;
}

.pdt-input{

    width: 20%;
    border-radius: 5px;
    text-align: center  ;
}


.d-input_sizs{
color: #000;
border: 0;
width: 100%;
font-size: 13px;
background-color: transparent;

}
.d-float-lbl{
    position: absolute;
    left:11%;
    top:0%;
    transform: translate(-50%,-50%);
    text-align: center;
   
    padding: 0px 1px;
    font-size: 12px;
  }

  .pdt-btn {
      width: 16%;
      border-radius: 5px;
      background-color: #ffe202;
      border: 0;
      color:#000;
      font-weight: 400;
     
     
  }

 .qty-size{
     font-size: 10px;
   
    position: absolute;
    transform: translate(-90%,0);
    top:120%;
    left:50%;
     font-weight: normal;


 }

 .dropdown-item {
     font-size: 13px;
 }

 select:focus-within {
    border-color: red !important;
    box-shadow: 0 0 0 0.2rem transparent     !important;
} 
