@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Roboto:wght@300;400&display=swap');

.pendingAwb {
    border: 1px solid rgba(0, 0, 0, 0.356);
    width: 95%;
    height: 50px;
    border-radius: 5px;
}

.pendingAwb1 {
    border: 1px solid rgba(0, 0, 0, 0.356);
    width: 95%;
    height: 100px;
    border-radius: 5px;
}




.badgeYellow {
    background-color: #ffe202;
    padding: 1px 3px;
    border-radius: 5px;
    font-size: 0.75em;
    font-weight: 600;
    width: 83px;
}

.delADS {
    font-size: 0.75em;
    font-weight: 500; 
    color:rgba(0, 0, 0, 0.73)
}

.dstStat {
    font-size: 1em;
    font-weight: 700;
    font-family: 'Arial','Roboto';
    }

    .bgScan {
        background-color: #e4f5ff;
        border-radius: 4px;
        width: 100%;
        height: 50px;
        font-size: 1.1em;
        font-family: 'Lato';
        color: black;
        border: 0 solid #e4f5ff;

    }
    .prtRound {
        background-color: #ffe202;
        height: 35px;
        width: 35px;
        border-radius: 50%;
        padding:px;
        box-shadow: rgba(0, 0, 0, 0.356);
    }

    .bluedart {
        height: 18px;
        width: 100px;
    }
    .dtdc {
        height: 28px;
        width: 100px;
    }
    .delhivery {
        height: 20px;
        width: 100px;
    }

    .barWidth {
        
        display: block;
        width: 100%;
    }