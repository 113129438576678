.rlogo {
    height: 95px;
    width: 100vw;
    position: absolute;
    top:0;
    transform: translate(-2%,-20%);
  /* background-color: #0d1740; */
  width: 100%;
}

.fName{
    font-size: 0.86em;
    font-weight: 700;
    font-family: 'Lato';
    line-height: 15px;
}

.rAdd {
    font-size: 0.75em;
    font-family: 'Lato';
    line-height: 20px;
}
.amount {
    font-size: 0.85em;
    font-family: 'Roboto';
    letter-spacing: 0.01em;
}

.rTrans{
    position: absolute;
    top:0;
    left:0;
    transform: translate(40%,480%);
}
.bgGray{
    background-color: #f2f2f2;
}

.terms{
    font-size: 0.29em;
    line-height: 4px;
    align-items: center;
}

.charge{
    background-color: #e4f5ff;
    padding: 2px 10px;
    border-radius: 5px;
}

.gst {
    font-size: 0.65em;
    font-family: 'Roboto';
}

.office {
    font-size: 0.35em;
    font-family: 'Lato';
}