.TabSelect{
    display: flex;
    justify-content: space-around;
}

.underLine{
    width: 50%;

    color:  #000;
    position: absolute;
    top:0;
    padding:2px 15px;
    border-bottom: 2.2px solid #000000;
    transform: translate(0%,-75%);
    animation-name: example1;
    animation-duration: 0.5s;
    animation-direction: reverse;
}

.underLine1{
    width: 50%;

    color:  #000;
    position: absolute;
    top:0;
    padding:2px 15px;
    border-bottom: 2.2px solid #000000;
    transform: translate(100%,-75%);
    animation-name: example;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

.activefnt {
    font-family: 'Lato','Roboto';
    font-size: 16px;
    color: #000;
    font-weight: 600;
}

.inActivefnt {
    font-family: 'Lato','Roboto';
    font-size: 16px;
    color: rgba(0,0,0,0.6);
    font-weight: 600;
}

@keyframes example {
    0%   {transform: translate(0%,-75%);}
    /* 25%  {transform: translate(25%,-75%);}
    50%   {transform: translate(50%,-75%);}
    75%  {transform: translate(75%,-75%);} */
    100%  {transform: translate(100%,-75%);}
  }

  @keyframes example1 {
    0%   {transform: translate(0%,-75%);}
    /* 25%  {transform: translate(25%,-75%);}
    50%   {transform: translate(50%,-75%);}
    75%  {transform: translate(75%,-75%);} */
    100%  {transform: translate(100%,-75%);}
  }

  .Out {
      height: 30px;
      width: 50px;
      background-color: #f7ea8b;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
  }


  .awb{
      font-family: 'Lato','Roboto';
      font-size: 0.95em;
      font-weight: 700;
      /* position: absolute; */
      /* right:0; */
      margin-top: 3px;
      line-height: 15px;
  }

  .status{
    font-family: 'Lato','Roboto';
    font-size: 0.85em;
   color: rgba(0,0,0,0.8);
  /* border: 1px solid rgba(0,0,0,0.45); */
padding: 2px 4px;
border-radius: 5px;
/* position: absolute; */
font-size: 700;
/* right:0; */

/* background-color: #f2f2f2; */
  }

  .progressBar{
     border-left: 2px dotted rgba(0,0,0,0.25);
    padding : 25px 0.5px;
  }

  .destHead{
    font-family: 'Lato','Roboto';
    font-size: 0.9em;
    font-weight: 400; 
    line-height: 0.9em;
  
  }

  .destStat{
    font-family: 'Roboto';
    font-size: 0.77em;
    font-weight: 400; 
    color: rgba(0,0,0,0.6);
    letter-spacing: 0.01em;
    /* padding-bottom: 14px; */
  }
.divider{
    opacity: 0.9;
 margin-top: 5px;
}

.dotedLine{
 border-bottom: 1.2px dotted rgba(0,0,0,0.15);
width: 95%;
}


.pdLoc1{
  font-size: 0.82em;
  line-height: 20px;
  font-family: 'Lato','Roboto';
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
text-align: left;
  
}

.verticlaTran{
  position: absolute;
  top: 0;
  transform: translate(0%,90%);
}
.close1{
  position: absolute;
  right:0;
  transform: translate(-60%,-30%);
  font-size: 1em;
  font-weight: 500;
}

.eta{
  font-size: 0.75em;
  color:gray;
}

.overlayPickup3 {
  background-color: black;
  /* position: absolute; */
  /* bottom:0; */
  height: 350px;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  bottom: 10px;
  display: flex;
  justify-content: center;
  font-size: 1.2em;
  letter-spacing: 0.01em;
  font-weight: 500;
  font-family: 'Lato','Roboto';
}

.myShipRnd {
  background-color: #00800038;
  width: 60px;
  height: 50px;
  border-radius: 10px;
}

.myShipRndLable {
  background-color: #f2f2f2;
  width: 60px;
  height: 50px;
  border-radius: 10px;
}

.myShipRndOfd {
  background-color: #fee10246;
  width: 60px;
  height: 50px;
  border-radius: 10px;
}