.spinOverlay{
    position: absolute;
    height: 100vh;
  
 top:0;
 /* transform: translate(50%,45vh); */

}

.loaderCon{
    background-color: rgba(0, 0, 0, 0.096);
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.loader{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    background-color: white;
    width: 200px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.096);
}

.bgClose{
    background-color: #ffe202;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}