
.listCont{
padding-top: 0px;
/* width: 100%; */
border-radius: 5px;
}

.listStyle{
    list-style: none;
    font-size: 14px;
    color: black;
    padding-right: 20px;
    position: absolute;
    z-index: 10;
    color:white;
    border-radius: 5px;
   background-color: black;
    margin-top: 5px;
   
}

li:hover{
    cursor: pointer;
}

.background-clr{
    background-color: #f2f2f2;
}

.parcel-input {
    border: none;
    width: 80%;
    background-color: transparent;
    font-size: 16px;
    font-family: 'Lato','Roboto';
    padding-left: 10px;
}

.parceInputStyle{

    border-bottom: 1px solid rgba(151,151,151,0.4);
    width: 90%;
 padding: 5px 0px;
    background-color: white;
    margin-bottom: 20px;
    border-radius: 10px;
    display: flex;
    padding-right: 10px;
    justify-content: space-between;
}

.parceInputStyle1{

    border-bottom: 1px solid rgba(151,151,151,0.4);
    width: 90%;
 padding: 5px 10px 1px 10px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
}

.parcelHeader{
    background-color: #ffe202;
    height: 50px;
    display: flex;
    align-items: center;
}

.parceBtn{
    display: flex;
    width: 100%;
    position: absolute;
    bottom:0;
   background-color: blue;
   padding: 0px;
}

.back{
    cursor: pointer;
}

.instruction{
    background-color: transparent;
    border-radius: 10px;
    padding: 10px;
    display: flex;
 
    align-items: center;
    border-bottom: 1px solid rgba(151,151,151,0.4);
}

.instrFont{
    font-size: 12px;
}

.quote1{
    position: absolute;
    width: 100%;
    bottom: 0;
   padding-bottom: 10px;
   padding-left: 12px;
    
}

.pQty{
    width: 65px;
}
.cod{
    width: 100px;
}

.rates-con
{
   width: 98%;
   margin-left: 5px;
   border-radius: 4px;
    border: 1px solid rgba(0,0,0,0.15);

}

.label-fnt
{
    line-height: 8px;
    font-size: 14px;
    font-family: 'Lato','Roboto';
    color: rgba(0,0,0,0.5);
}

.volumetric{
    font-size: 12px;
    font-style: italic;
}

.form-floating {
    /* border: 1px solid black; */

}

.btn-success{
    width: 95%;
    background-color: #ffe200;
    box-shadow: rgba(0,0,0,5.15);
    border:none;
    padding: 10px 10px;
    border-radius: 8px;
    color:black;
    font-weight: 400;
    font-weight: 1em;
    font-family: 'Lato','Roboto';
}

.wgtTerms{

    border: 1px solid #f7f7f7;
    padding: 4px 10px;
    border-radius: 10px;
    background-color: white;
    font-size: 0.7em;
    line-height: 14px;
        position: relative;
  width: 96%;
}

.leftB{
    position: absolute;
    width: 8px;
    height: 100%;
    background-color: black;
    border-radius: 10px 0 0 10px;
    top:0;
}

.inputPpx{
    border: none;
    width: 100%;
  background-color: transparent;
  font-size: 0.9em;
}

.ppxField{
    border: 1px solid rgba(0,0,0,0.35);
    width: 100%;
    padding:5px 0px;
    border-radius: 7px;
    background-color: white;
}

.store{
    font-size: 0.7em;

}

.divide1{
    opacity: 0.1;
}

.others{
    border: none;
    width: 100% ;
    padding: 5px 10px;
    font-size: 0.8em;
    background-color: transparent;
}

.buyformeCon{
    display: flex;
    border: 1px solid white;
    display: flex;


}
.bHead{
    font-size: 0.95em;
    font-weight: 700;
    font-family: 'Lato','Roboto';
    
}

.buyText {
    font-size: 0.7em;
    font-size: 400;
}
.promo1 {
    border: none;
    font-size: 0.85em;
    width: 100%;

}

.boi {
    text-decoration-line:underline;
}
.totals{
    font-weight: 0.8em;
    font-weight: 500;
}

.label-fntH{
font-weight: 500;
font-size: 0.8em;
color: rgba(0, 0, 0, 0.75)
}

.delAd{
    font-size: 0.85em;

}

.pk-con1{
    background-color: #ffe10244;
    padding: 4px 5px;
    margin: 2px 20px;
    border-radius: 15px;
   
}

.parcelHt{
    height: 55px;
}

.content{
    font-size: 0.9em;
    font-family: 'Roboto','Lato';
}

.custBorder{
    border: 1px solid #cecbcb;
    border-radius: 5px;
}

.custBorderErr{
    border: 1px solid rgb(148, 8, 8);
    border-radius: 5px;
    
}

.boxLabel{
font-size: 0.75em;
}