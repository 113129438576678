.businP {
    padding: 30px 30px;
}

.BusinB {
    background-color: #212936;
}

.buisFont {
    font-family: 'poppins';
    letter-spacing: 0.02px;
    font-size: 2.2em;
    font-weight: 500;
}

@media (min-width: 340px) { 
    .buisFont {
        font-family: 'poppins';
        letter-spacing: 0.02px;
        font-size: 1.6em;
        font-weight: 500;
    }
    .buisImg {
        width: 60%;
        height: 90%;
    }
}

@media (min-width: 576px) { 
    .buisFont {
        font-family: 'poppins';
        letter-spacing: 0.02px;
        font-size: 1.8em;
        font-weight: 400;
        text-align: left;
    }
    .buisImg {
        width: 60%;
        height: 90%;
    }
}

@media (min-width: 768px) { 
    .buisFont {
        font-family: 'poppins';
        letter-spacing: 0.02px;
        font-size: 1.8em;
        font-weight: 500;
        text-align: left;
    }
    .buisImg {
        width: 100%;
        height: 100%;
    }
}


@media (min-width: 996px) { 
    .buisFont {
        font-family: 'poppins';
        letter-spacing: 0.02px;
        font-size: 2.2em;
        font-weight: 500;
    }
    .buisImg {
        width: 100%;
        height: 100%;
    }
}

@media (min-width: 1200px) { 
    .buisFont {
        font-family: 'poppins';
        letter-spacing: 0.02px;
        font-size: 2.2em;
        font-weight: 400;
    }
    .buisImg {
        width: 100%;
        height: 100%;
    }
}

@media (min-width: 1400px) { 
    .buisFont {
        font-family: 'poppins';
        letter-spacing: 0.02px;
        font-size: 2.2em;
        font-weight: 500;
    }
    .buisImg {
        width: 100%;
        height: 100%;
    }
}

.businesHeading {
    /* border-bottom: 2px solid #ffe202; */
    /* line-height: -12px; */
    color: #ffe202;
    font-family: 'poppins';
    font-weight: 600;
}

blockquote::before {
    content: open-quote;
  }
  blockquote::after {
    content: close-quote;
  }
  blockquote {
    quotes: "“" "”" "‘" "’";
  }