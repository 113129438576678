.kycImges {
    object-fit: cover;
    height: 300px;
    width: 100%;
}


@media   {
    .footer,
    #non-printable {
        display: none !important;
    }
    #printable {
        display: block;
    }
}


.textSmall {
    font-size: '0.75em';
    font-family: 'Lato';
}
