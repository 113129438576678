

.book-btn{
background-color: black;
padding:7px 15px;

width: 58%;
}

.payment-btn

{
    background-color: #008000;
    width: 42%;
    padding: 7px 15px;
    color: white;
    display: flex;
    justify-content: flex-end;
}

.international{
    margin-top: 5px;
    background-color: aqua;
    height: 200px;
    overflow-y: scroll;
    z-index: 10;
    /* padding: 4px 15px; */
    /* background-color: rgb(196, 191, 191); */
    /* width: 92%;
    z-index:10;
    position: absolute; */
   
}

.zipcode {
    
    position: relative;
    display: inline-block;
}

.cntCode{
    font-family: 'Lato';
    font-size: 1em;
    font-weight: 700;
  padding-top: 15px;
    /* background-color: aqua; */
}

.inputZip{
  padding: 0px 20px;
}

.spinner{
  width: 1.2em;
  height: 1.2em;
}