
.bg-color{
    background-color: #212936;
}

.txt-clr{
    color: #fff;
    font-size: 10px;
    
}

.txt-clr1{
  color: #fff;
  font-size: 0.85em;
  font-family: 'Roboto','Lato';
  font-weight: 400;
  color: dark;
  opacity: 0.86;
}

.border{
    border-radius: 10px;
}

.footer-mar{
    margin-top: 10%;
   height: 0% !important;
    
}



@media (min-width: 350px) { 

    .small-footer{
        display: block;
    }
    .small-btm{
        margin-bottom: 20%;
    }
    .google{
        font-size: 10px;
    }
}



@media (min-width: 576px) { 

    .small-footer{
        display: none;
    }

    .small-btm{
        margin-bottom: 20%;
    }
 }


 @media (min-width: 768px) { 


  }

  .fAdd {
    font-size: 0.75em;
    font-weight: 400;
    line-height: 20px;
    font-family: 'Roboto','Lato';
  }

  .flogo {
    width: 200px;
    opacity: 0.75;
  }

  .slogan{
    font-size: 0.75em;
    font-weight: 400;
    font-family: 'Roboto','Lato';
    color: white;
    opacity: 0.6;
  }

  .fFont{
    font-size: 0.75em;
    font-family:'Roboto','Lato';
    font-weight: 300;
  }

  .fMenu {
    font-size: 1.2em;
    font-family: 'Roboto','Lato';
    font-weight: 300;
  }

  .footerFnt {
    font-size: 1.2em;
  }