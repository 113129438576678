.bgCash {
    background-color: #f2f2f2;
}

.cahsRound {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e4f5ff;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.cashBtm {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.scrollable-div2{
    height: 75vh;
    /* overflow-y: auto; */
  }

  .cashSearch {
    width: 82%;
  }

  .dateCon {
    height: 400px;
  }

  .fromW {
    width: 330px;
  }


  