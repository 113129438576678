.currentLoc {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    align-items: center;

}

.currentCaption {
    display: flex;
    flex-direction: column;

}

.locCaption{
    font-size: 12px;
   color: rgba(0,0,0,0.6);
   font-family: 'Lato';
    font-weight: 500;
}

.saveStyle {
    padding:5px;
    color: gray;
    font-size: 13px;
}

.addDetail{
    font-size: 13px;
    font-family: 'Lato','Roboto';
    display: -webkit-box;
    max-width: auto;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.addDetail1{
  font-size: 13px;
  font-family: 'Lato','Roboto';
 
}



.avatar-placeholder {
    float: left;
    width: 48px;
    height: 48px;
    margin-right: 12px;
    margin-bottom: 5px;
  }

  .avtar-button {
    float: right;
    width: 60px;
    height: 25px;
    margin-left:12px;
    margin-bottom: 5px;
    background-color: gray;
  }

  .avtar-card{
    float: left;
    width: 120px;
    height: 48px;
    margin-right: 12px;
    margin-bottom: 5px;
    border-radius: 10px;
  }

  .placeholder {
    display: inline-block;
    background-color: #b0c0c7;
    animation-name: shine;
    animation-duration: 2.4s;
    animation-iteration-count: infinite;
  }
  
  @keyframes shine {
    0% { 
      opacity: 1;
    }
    50% {
      opacity: 0.35;
    }
    100% {
      opacity: 1;
    }
  }

  .cursor{
    cursor: pointer;
  }

  .floatCity{
    position: absolute;
    transform: translate(-50%,-100%);
    right:0;
    display: flex;
    justify-content: center;
  }

  .disableLoc {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
  }

  .delte{
    /* position:  absolute; */
    /* right:0; */
    background-color: #f2f2f2;
    z-index: 10;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
.waiting{
  height: 100%;
  width: 100%;
background-color: transparent;
display: flex;
justify-content: center;
align-items: center;
}
