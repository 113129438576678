

@media (min-width: 376px) { 

    .small-trc {
        display:block;
    }
}


@media (min-width: 576px) { 

 .small-trc {
     display:none;
 }

 .e-space {
    margin-top: 5%;
}
.e-margin {
    margin-top: 5%;
}

 

.about-size{
    margin-top: -10%;
}

}

@media (max-width: 575.98px) { 
    .e-space {
        margin-top: 8%;
    }

    .e-margin {
        margin-top: 5%;
    }

    .small-trc {
        display:block;
    }
 }

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
    .small-trc {
        display:none;
    }
    
  }

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) { 
    .e-space {
        margin-top: 7%;
    }
    .small-trc {
        display:none;
    }
    
 }

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) { 
    .e-space {
        margin-top: 8%;
    }
    .small-trc {
        display:none;
    }
   
 }

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
    .e-space {
        margin-top: 10%;
    }
    .e-margin {
        margin-top: 5%;
    }
    .small-trc {
        display:none;
    }
 }

.bgColor {
    background: rgb(2,51,107);
background: -moz-linear-gradient(180deg, rgba(2,51,107,1) 28%, rgba(17,200,234,1) 100%, rgba(0,0,0,1) 100%);
background: -webkit-linear-gradient(180deg, rgba(2,51,107,1) 28%, rgba(17,200,234,1) 100%, rgba(0,0,0,1) 100%);
background: linear-gradient(180deg, rgba(2,51,107,1) 28%, rgba(17,200,234,1) 100%, rgba(0,0,0,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#02336b",endColorstr="#000000",GradientType=1);
}


.bgPickup{
background-color: white;
padding: 50px 20px;
border-radius: 20px;
height: 480px;
}

.shipGradient {
    background: rgb(131,58,180);
background: -moz-linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);
background: linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#833ab4",endColorstr="#fcb045",GradientType=1);
}

.homeBack{
    background-color: #f2f2f2;
}

.headingCap {
    color: white;
    font-size: 1.2em;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.head3{
       
font-family: Poppins;
font-style: normal;
font-weight: 600;
font-size: 2.5em;
line-height: 20px;
margin-top: 70px;
/* identical to box height */
text-align: left;
letter-spacing: 0.04em;

color: white;
}

.smallHead {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 1.0em;
    line-height: 20px;
    margin-top: 35px;
    /* identical to box height */
    text-align: left;
    letter-spacing: 0.04em;
    
    color: white; 
}

.smallHead1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 0.9em;
    line-height: 20px;
    margin-top: 35px;
    /* identical to box height */
    text-align: left;
    letter-spacing: 0.04em;
    
    color: white; 
}


.bookBtn {
    margin-top: 80px;
    background-color: #ffe202;
    padding: 5px 10x;
    width: 180px;
    height: 40px;
    text-align: center;
    border-radius: 10px;
    font-family: 'Roboto';
}

.bgMain {
    background-color: #ffe202   ;
    padding:  20px 15px;
}

.networkFloat {
    position: absolute;
    background-color: rgba(33, 41, 54, 0.295);
    transform: translate(5%,-30%);
    bottom: 0;
    border: 10px;
    

}

.whastImg {
    width: 50px;
    height: 50px;
}

.spinOverlay1{
    position: absolute;
    height: 100vh;
    width: 100vw;
  background-color: rgba(0, 0, 0, 0.365);
 top:0;
 left:0;
}

.bgGray {
    background-color: #f2f2f2;
}

.topH {
    background-color: #ffe202;
    width: 100%;
}