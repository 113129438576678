.searchBar{
    height: 50px;
    width: 100;
    display: flex;
    align-items: center;
}

.wait{
    background-color: rgba(0, 0, 0, 0.192);
    height: 100vh;
    width: 100%;
}

.roundCir
{
    height: 35px;
    width: 45px;
    border-radius: 50%;
    background-color: #ffe202;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.storeLogo{
    height: 70px;
    width: 90vw;
}

.storeBg {
     background-color: #0D1740;
     width: 100vw;
}

.pikName2{
    font-size: 0.9em;
    font-family: 'Roboto','Roboto';
    font-weight: 600;
    color:black;
}

.pikAdd2 {
    font-size: 0.75em;
    font-family: 'Roboto','Roboto';
    color: #242735;
}

.mobErr {
    font-size: 0.75em;
    color: rgb(196, 14, 14);
    font-family: 'Roboto','Lato';
}

.sent {
    /* background-color: #008000; */
    font-size: 0.95em;
    /* border: 1px solid #008000; */
    border-radius: 5px;
    /* width: 83%; */
    color: #008000;
    font-family: 'Roboto','Lato';
    font-weight: 500;
   
}

.btnOpacity{
    animation-name: example;
    animation-duration: 4.0s;
    animation-direction:alternate;
}

@keyframes example {
    0%   {opacity: 0;}
    /* 25%  {transform: translate(25%,-75%);}
    50%   {transform: translate(50%,-75%);}
    75%  {transform: translate(75%,-75%);} */
    100%  {opacity: 1;}
  }


  .sentLabel{
    display: block;

    animation-name: example1;
    animation-duration: 4.0s;
    animation-direction:alternate;
}

@keyframes example1 {
    0%   {opacity: 1;}
    /* 25%  {transform: translate(25%,-75%);}
    50%   {transform: translate(50%,-75%);}
    75%  {transform: translate(75%,-75%);} */
    100%  {opacity: 0; display: none;}
  }

  .pickupCard1{
    background: transparent;
    
    border: 1px solid rgba(19, 18, 18, 0.4);
    border-radius: 10px;
    width: 90%;
    }

    .pickupCardErr{
        background: transparent;
        
        border: 1px solid #b40202;
        border-radius: 10px;
        width: 90%;
        }