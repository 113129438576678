.timeDate{
    font-size: 0.8em;
  
   font-family: 'Lato','Roboto';
   letter-spacing: 2%;
   font-weight: 400;
}

.prgBar{
    height: 60px;
    border-left: 2px solid rgba(0,0,0,0.2);
    display: flex;
    justify-content: center;
    align-items: center;
  

}

.hgt{
    height: 25%;
}




  .curDot{
     
      height: 23px;
      width: 23px;
      background-color: white;
      border-radius: 50%;
    
      animation-name: example2;
      animation-duration: 0.9s;
      animation-iteration-count: infinite;
  }

  @keyframes example {
    
    0% { 
       opacity: 0;
      }
     
     100% {
       opacity: 1;
     }
  }


  @keyframes example2 {
    
    0% { 
        border: 8px solid green;
      }
     
     100% {
        border: 1px solid green;
     }
  }

  .locFnt{
    background-color: #f2f2f2;
      font-size: 0.8em;
      padding:2px 8px;
      border-radius: 5px;
      line-height: 17px;
      font-family: 'Lato','Roboto';
      width: 100%;
  }

  .pkBoyCon{
    background: #FFFFFF;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    border-radius: 10px;
    font-family : 'Lato','Roboto';
   background-color:#f2f2f2;
    padding: 2px 8px;
    width: 250px;
    opacity: 0.3;
    animation-name: example;
    animation-duration: 0.9s;
    animation-fill-mode: forwards;
  }

  

  .pkAdd{
    font-family :'Roboto';
    font-size : 0.71em;
    padding-top: 4px;
    line-height:12px;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  
  }

.liveDate{
  font-size: 0.8em;
padding: 1px 2px;
}
