.trck
{
    background: #fff;
    /* opacity: 0.4; */
     border: 1px solid rgba(0, 0, 0, 0.4);
     box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5); 
    border-radius: 20px 20px 0px 0px;
    padding-top: 3%;
    padding-bottom: 4%;

}
.trc-input{
    border: 0px ;
    font-size: 16px;
    background-color: transparent;
}

.border-track{
    border-bottom: 1px solid #ffe202;
}