.btn-warning
{
    background-color: #FFE500 !important;
    border-color: #FFE500 !important;
    border-radius: 10px !important;
  font-size: 18px;
  font-family: 'Lato','Roboto';
}

.btn {
    width: 96%;
   
}
