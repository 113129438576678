.cardCnt
{
 
    background-color: #ffe202;
padding:10px 10px;
border-radius: 10px;
}

.contactDiv {
    background-color: #ffe10242;
    border-radius: 10px;
    height: 320px;
}

.contactDiv1 {
    background-color: white;
    border-radius: 10px;
    height: 300px;
    display: flex;
    text-underline-position: below;
    align-items: center;
}

.headingLn{
font-size: 1.2em;
font-weight: 700;
}

.RoundCircle {
    background-color: black;
    height: 40px;
    width: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.RoundCircle1 {
    background-color: black;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.conFnt {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 0.95em;
    color: '#0d1740'
    
}

.cntBg {
    background-color: #004247;
}