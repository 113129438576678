.verify-border {
    background: #FFFFFF;
border: 1px solid #F8F7FA;
box-sizing: border-box;
box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 10px;
padding: 1%  5% 3% 4%;
}

.verify-font{
    font-size: 13px;
    color: gray;
}

.verify-input
{
width: 100%;
  border: 0px;
  background-color: transparent;
}

.bgVerifty {
  background-color: white;
}

.bgInput {
  background-color: white;
  padding: 15px 4px;
  border-radius: 10px;
}


.headLogin {
  background-color: #F2F4F9;
}


.loginTxt {
  font-size: 0.85em;
  font-weight: 400;
  font-family: 'poppins','Lato';
color:black
}

.singUpTxt {
  font-size: 1.2em;
  font-family: 'Poppins';
  font-weight: 500;
  color: black;
  padding: 20px 20px;
}

.mobPadding {
  padding: 10px 10px;
}

.bg-blue{
  background-color :black;
}

.slognLine {
  font-family: 'poppins';
  font-weight: 500;
  font-size: 1em;
}

.loginCenter {
  position: absolute;
  bottom: 0;
  transform: translate(0%,-80%);
  width: 90%;
  background-color: white;
}
.logoCenter{
  position: absolute;
  width: 85%;
  top:0;
  left:0;
  transform: translate(8%,130%);
}

.slognFloat {
  position: absolute;
  top:0;
  left:0;
  transform: translate(8%,60%);

}

.accTerm {
  font-size: 0.75em;
  font-weight: 400;
  font-family: 'Roboto';
  color: black;
}

.inputBtm {
  border: 0;
  outline: 0;
  border-radius: 0;
  border-bottom: 2px solid #ffe202;
  font-size: 1.2rem;
  color: black;
        
}

.inputBtm {
  border: 0;
  outline: 0;
  border-radius: 0;
  border-bottom: 2px solid #ffe202;
  font-size: 1.2rem;
  color: black;
        
}


.inputBtmErr {
  border: 0;
  outline: 0;
  border-radius: 0;
  border-bottom: 2px solid #e72231;
  font-size: 1.2rem;
  color: black;
        
}