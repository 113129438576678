.overlayPickup {
  background-color: black;
  position: absolute;
  bottom:0;
  height: 200px;
  width: 90%;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  bottom: 10px;
  display: flex;
  justify-content: center;
  font-size: 1.2em;
  letter-spacing: 0.01em;
  font-weight: 500;
  font-family: 'Lato','Roboto';
}


.overlayPickup1 {
    background-color: black;
    position: absolute;
    bottom:0;
    height: 160px;
    width: 90%;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    bottom: 10px;
    display: flex;
    justify-content: center;
    font-size: 1.2em;
    letter-spacing: 0.01em;
    font-weight: 500;
    font-family: 'Lato','Roboto';
  }
  
  .overlayPickup2 {
    background-color: black;
    position: absolute;
    bottom:0;
    height: 230px;
    width: 90%;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    bottom: 10px;
    display: flex;
    justify-content: center;
    font-size: 1.2em;
    letter-spacing: 0.01em;
    font-weight: 500;
    font-family: 'Lato','Roboto';

  }

.waitPik
{
    height: 40px;
    width: 40px;
    background-color: #f7f2cc;
    border-radius: 50%;
  padding: 2px;
    animation-name: example2;
    animation-duration: 0.9s;
    animation-iteration-count: infinite;
    display: flex;
    justify-content: center;
}


@keyframes example2 {
    
    0% { 
        border: 15px solid #f3e472;
      }
     
     100% {
        border: 1px solid #f3e472;
     }
  }

  .ptext{
      font-size: 0.6em;
      font-family: 'Lato','Roboto';
      
  }


  .ptext1{
    font-size: 0.95em;
    font-family: 'Poppins','Roboto';
    }

  .pic{
    
    height:50px;
    width: 50px;
    border-radius: 50%;

}

.BoyName {
    margin-top:5px;
font-family: 'Lato','Roboto';
color: black;
font-size: 0.95em;
font-weight:500;  

line-height: 20px;
  }

  .BikeN {
    font-family: 'Lato','Roboto';
    color: rgba(0, 0, 0, 0.85);
    font-size: 0.65em;
    font-weight : 400;
    
  }

  .pdividie{
      opacity: 0.1;
      
  }

  .drp{
      font-size: 0.5em;
      font-family: 'Lato','Roboto';
  }

  .pdLabel{
    font-size: 0.65em;

  }
  .pdLoc{
    font-size: 0.7em;
    line-height: 20px;
    font-family: 'Lato','Roboto';
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;

    
  }


  .call{
    height:25px;
    width: 25px;
    border-radius:50%;
    background-color:black;
    display:flex;
    justify-content:center;
    align-items:center;
  }

  .onwB{
    background-color: #ffe202;
    color: black;
    padding : 1px 4px;
    border-radius: 4px;
    position: absolute;
    right:0;
    transform: translate(-15%,-10%);
  }

  .epl {
    font-size: 0.85em;
    font-family: 'Lato';
    opacity: 0.8;
  }

  .LocalWait{
    position: absolute;
    top:0;
    height: 100vh;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .marker-pos1{
    position: absolute;
    top:0;
   width: 100%;
    /* height: 100%; */
    transform: translate(0%,88%);

}



.overlayPickup3 {
  background-color: black;
  position: absolute;
  bottom:0;
  height: 330px;
  width: 90%;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  bottom: 10px;
  display: flex;
  justify-content: center;
  font-size: 1.2em;
  letter-spacing: 0.01em;
  font-weight: 500;
  font-family: 'Lato','Roboto';

}

.hiLeft {
  padding-left: 12px;
}