.km {
    font-size: 1.2em;
    font-weight: 600;
}

.pickupCard{
background: transparent;

border: 1px solid rgba(151, 151, 151, 0.4);
border-radius: 10px;
width: 90%;
}

.floatLbl {
position: absolute;
top:0;
transform: translate(0%,-50%);
background-color: white;
font-size: 0.8em;
font-family: 'Roboto';

}

.pikName{
    font-size: 0.9em;
    font-family: 'Roboto','Roboto';
}

.pikAdd {
    font-size: 0.75em;
    font-family: 'Roboto','Roboto';
    color: #242735;
}

.accept{
   background-color: #008000;
   border-radius:2px;
  
}

.tran{
    position: absolute;
    left:0;
    transform: translate(-10%,40%);
}

.curLoc{
    font-size: 1.1em;
    font-weight: 600;
}

.circle
{
    height: 30px;
    width: 30px;
    border-radius: 50%;
    padding: 2px;
    background-color: #fde73d;
}

