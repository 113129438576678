
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600&display=swap');


.inputTxt{
    border: none;
    background-color: transparent;
    font-size: 0.9em;
    width: 100%;
}

.support {
    /* top:0; */
    /* right: 0; */
    /* position: absolute; */
    /* transform: translate(0%, 20%);   */
    font-weight: 500;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;  
}

*{
    padding:0;
    margin:0;
    box-sizing: border-box;
}

.menu_active {
   
    font-weight: bold;
    text-decoration-line: none;
}

.navbar a {
   text-transform: capitalize;
    color: #000;
}

.btn-yello {
    background-color: #FFE202;
    font-family: 'Poppins','Lato';
    font-weight: 500;
    font-size: 0.85em;
    color: black;
    padding: 20px 10px;
    border-radius: 10px;
}


@media (min-width: 340px) { 
    .logo-img{
        height:10%;
        width:  140px;
    }
    .menu_bar{
        font-size: 14px;
       
    }
    .menu_bar {

        color: #3F3D56;
        padding: 2px 6px;
        font-size: 17px;
        
    }
    }

@media (min-width: 576px) { 
    .logo-img{
        height:10%;
        width:  160px;
    }
    .menu_bar{
        font-size: 14px;
       
    }
    }


@media (max-width: 575.98px) { 
    .logo-img{
    height:10%;
    width:200px;
}
.menu_bar{
    font-size: 14px;
   
}
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {  
    .logo-img{
        height:12%;
        width: 60%;

    }
    .menu_bar{
        font-size: 12px;
      
      
    }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) { 
    .logo-img{
        height:15%;
        width: 40%;
    }
}
/* 
// Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
    .logo-img{
        height:20%;
        width: 40%;
    }  }

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
    .logo-img{
        height:20%;
        width: 40%;
    }
    .menu_bar{
        font-size: 14px;
       
    }
 }


 .bgBlue {
    /* background: rgb(2,51,107);
    background: -moz-linear-gradient(180deg, rgba(2,51,107,1) 100%, rgba(0,0,0,1) 100%, rgba(0,0,0,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(2,51,107,1) 100%, rgba(0,0,0,1) 100%, rgba(0,0,0,1) 100%);
    background: linear-gradient(180deg, rgba(2,51,107,1) 100%, rgba(0,0,0,1) 100%, rgba(0,0,0,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#02336b",endColorstr="#000000",GradientType=1); */
   /* background-color: white !important; */
    /* background-color: transparent !important ; */
    border-radius: 0px !important;

 }

 .NavFont {
    font-weight: 300;
    font-size: 0.85em;
    font-family: 'Inter', sans-serif;
}

.trkBkg1  {
    background-color: white;
    border: 2px solid #ffe202;
    padding: 2px 5px;
    width: 230px;
    border-radius: 5px;
    font-weight: 500;
    font-weight: 0.85em;
}


.trkBkg  {
    background-color: white;
    border: 2px solid white;
    padding: 2px 5px;
    width: 200px;
    border-radius: 5px;
    font-weight: 500;
    font-weight: 0.85em;
}

.menuBBar {
    background-color: white;
    position: absolute;
    right:0;
    top:0;
    padding: 20px 20px;
    /* transform: translate(0%,-40%); */
    width: 300px;
}

.trackShip {
    font-size: 0.75em;
    font-family: 'Roboto';
    font-weight: 400;
    color: black;
}

.lcD {
    font-size: 0.95em;
    font-family: 'Roboto';
    font-weight: 500;
    
}

.menuBarI {
    z-index: 10;
}

.menuBar2 {
    z-index: 1;
}

/* .cursor {
cursor: auto;
} */