@media (min-width: 376px) { 

    .about-font{
        font-size: 0.95em;
        font-family: 'Lato';
        letter-spacing: 0.1px;
        color: #21366c;
        padding: 0px 10px;
    }
 }

 @media (min-width: 346px) { 

    .about-font{
        font-size: 0.95em;
        padding: 0px 0px;
        line-height: 22px;
        padding: 0px 10px;
    }
 }

@media (min-width: 576px) { 

    .about-font{
        font-size: 0.95em;
        padding: 0px 0px;
        line-height: 22px;
        padding: 0px 10px;
    }
 }

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
    .about-font{
        font-size: 0.95em;
        padding: 0px 0px;
        line-height: 22px;
    }
 }

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
    .about-font{
        font-size: 0.95em;
        padding: 0px 0px;
        line-height: 22px;
        padding: 0px 10px;
    }
 }

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
    .about-font{
        font-size: 1.5em;
        padding: 0px 20px;
        line-height: 34px;
        font-family: 'Lato';
        letter-spacing: 0.02px;
        font-weight: 700;
        color: black;
        opacity: 0.9;
    }
 }

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) { 
    .about-font{
        font-size: 1.5em;
        padding: 0px 20px;
        line-height: 30px;
    }
 }

 .aboutBtn {
    width: 25%;
 }