
.page {
    width: 180mm;
    /* border: 1px #D3D3D3 solid;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); */
}
.subpage {
    border: 0.1px white solid;
    height: 111.4mm;
    width: 67mm;
    /* outline: 2cm #FFEAEA solid; */
}




.labelTxt {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 0.65em;
    line-height: 13px;
}

.borderSqu {
    border: 1px solid black;
    border-radius: 10px;

    
}

.btmBorder {
    border-bottom: 1px solid black;
    
}
.lblMob {
    font-size: 0.85em;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 10px;
}

.lblAds {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.80em;
    font-weight: 700;
    text-transform:lowercase;
    line-height: 15px;
}



  .dstCitty {
    font-size: 0.95em;
    font-weight: 700;
    font-family: 'Roboto';
    line-height: 18px;
  }
  
  .borderLeft {
    border-left: 1px solid black;
  }

  .delAdd{
    font-size: 0.65em;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
    color : black;
    line-height: 13px;
  }

  .lblFormat {
    width: 78mm;
  }

 .topBd {
  border-bottom: 1px solid black;
 }

.retrunAdd{
  font-size: 0.45em;
  font-weight: 500;
  font-family: Arial, Helvetica, sans-serif;
  color : black;
  line-height: 9px;
}