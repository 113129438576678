.Rate_back{
 height: 15px;
 width: 15px;
 border-radius: 50%;
 background-color: blue;
}

.Rate-con{
 
  padding: 5px 10px;
  width: 100%;
  height: 70px;
  border-radius: 10px;
  background-color: white;
  display: flex;
  align-items: center;
  /* opacity: 0.2; */
  border: 1px solid rgba(151, 151, 151, 0.2);
}

.Rate-con1{
 
  padding: 5px 10px;
  width: 100%;
  height: 70px;
  border-radius: 10px;
  background-color: #F2FBF7;
  display: flex;
  align-items: center;
  /* opacity: 0.2; */
  border: 1px solid #004246;
}

.dst-route{
  background-color: #ffe202;
  padding: 1px 5px;
  border-radius: 10px;
  font-size: 0.85em;
}



.selecOpt{
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: white;
  /* padding : 10px 10px; */
  border: 2px  solid  #ffe202 ;
  /* padding: 5px 5px; */
  position: relative;
}


.selecOpt1 {
position: absolute;
margin-top: -2px;
margin-left:-2px;
/* transform: translate(50%,50%); */
height: 25px;
width: 25px;
border-radius: 50%;
background-color: transparent;
display: flex;
justify-content: center;
align-items: center;
}


.radio{
  background-color: #ffe202;
  height: 15px;
  width: 15px;
  border-radius: 50%;

}


.delivery-lab{
  font-size: 0.75em;
  color: rgb(99, 96, 96);
  font-family: 'Roboto','Lato';
}

.rate-ls{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
 padding: 2px 15px;
  background-color: #ffe202;
  border-radius: 10px;
}

.ru-mar{
  padding-bottom: 2px;
}

.btnNextCon{
  position: absolute;
  bottom:0;
  transform: translate(0%,70%);
  width: 100%;
}

.btn-Next {
  border: none;
  padding: 10px 10px;
  background-color: #ffe202;
  width: 100%;
  border-radius: 5px;
  
}

.Plogo{
  height:55px;
  width: 55px;
  padding: 3px;
}

.ServiceName{
  font-weight: 600;
  font-family: 'Roboto','Lato';
}

.rateHt{
  height: 280px;
 }

 .rateHt1{
  height: 80%;
 }

 .rateWt {
  width: 105%;

 }