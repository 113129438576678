.delete {
    position: absolute;
    right:0;
    background-color: rgb(175, 6, 6);
    height: 100%;
    display: flex;
  
    padding:0px 6px;
    border-radius: 5px;
    /* transform: translate(5%,0%); */
}