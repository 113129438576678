.LocCard1{
   position: absolute;
   bottom:0;
   /* transform: translate(0%,-100%); */
   padding: 20px 10px;
   display: flex;
   flex-direction: column;
background-color:transparent;
   height: 250px;
   width: 100%;

}

.locationCon{
   height :200px;
   padding: 0px 10px;
   border-radius: 10px;
   background-color: white;
   box-shadow: (0,0,0,0,0.4);
}

.paymentLbl{
   font-size: 0.85em;
   font-weight: 500;
}

.paymentOption1 {
   background-color: #f2f2f2;
   width: 95%;
   padding: 2px 6px;
   font-size: 0.95em;
   font-family: 'Lato';
}

.fLogo {
   height: 50px;
   width: 200px;
}

.BoyName1 {
   margin-top:5px;
font-family: 'Roboto';
color: #008000;
font-size: 1.2em;
font-weight:700;  

line-height: 40px;
 }