.bgQueek{
    background-color: #f2f2f2;
    height: 100vh;
}

.paymentDone{
    height: 180px;
}

.cardPayment {
    background-color: #fff;
    height: 260px;
    width: 80%;
    border-radius: 10px;

}

.topHd {
    background-color: white;
    padding: 15px 5px;
    height: 50px;
    width: 100%;
    z-index: 10;
    border-radius: 10px 10px 0 0;
    display:flex;
    justify-content:center;
    align-items:center;
}

.pamentDetail {
    font-size: 0.85em;
    line-height:8px;
}

.paymentHistory {
    margin-top: 20px;
}

.receiptSent {
    padding: 5px 10px;
    text-align:center;
    border-radius: 5px;
    border : 1px solid #075E54;
}

.rightPk{
    position: absolute;
    right: 0;
    top:0;
    transform: translate(0%,30%);
}