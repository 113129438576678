.bg{
    background-color: black;
    border-radius: 5px;
   width:   130px;
}

.dash
{

    background-color: rgb(206, 203, 203);
    width: 40px;
    justify-content: center;
    height: 8px;
    border-radius: 5px;
  }

  .inputBg{
      background-color: white;
      border-radius: 10px;
      border: 1px solid rgba(0,0,0,0.5);
      padding: 8px 10px;
  }

.lines{
    width: 0.1em;
    height:auto;
    background-color: #f2f2f2;
  
}

.horizontal{
    width: 100%;
    height: 0.1em;
    background-color: #f2f2f2;
}

.alerts{
    position: absolute;
    top:0;
    left:0;
    transform: translate(80%,0%);
    color: white;
    font-size: 0.65em;
    background-color: rgb(192, 11, 11);
    padding: 1px 6px;
}