.input_pick {
 
border: 1px solid rgba(0, 0, 0, 0.9);
box-sizing: border-box;
border-radius: 5px;
/* padding: 6px 15px; */
width: 100%;
background-color: transparent;
}

.orgs {
  position: absolute;
  left: 0;
  top:0;
  transform: translate(50%,30%);

}


.input_sizs {
  font-size: 14px;
  color:black;
  background-color: transparent;
}





.trans {
    border-left: 1px dashed gray;
  height: 27px;
  margin: 2px 0px;
}

.trans2 {
  border-left: 1px dashed gray;
height: 40px;
margin: 2px 0px;
}

.autoCompletes {
  background-color: white;
  z-index: 50;
  font-family: 'Roboto';
  font-size: 0.75em;
  margin-top: 5px;
  padding: 5px;
  max-height: 180px;
  overflow: scroll;
  width: 280px;
}

.locHead1{
  font-size: 16px;
  font-family: 'Lato','Roboto';
  font-weight: 500;
  padding: 5px !important;
  margin: 0px!important;
}

.float-lbl{
  position: absolute;
  left:5%;
  top:0%;
  display: flex;
  justify-content: flex-start;
  transform: translate(0%,-50%);
  text-align: center;
  background-color: white;
  padding: 0px 2px;
  font-size: 12px;
 
}

.pd-label{
  font-size: 14px;
  margin-left:15px;

}
.reverse {
  background: #000;
border: 1px solid rgba(0, 0, 0, 0.15);
box-sizing: border-box;
border-radius: 5px;
padding: 4px 4px;
margin-right: 10%;
cursor:pointer;

left: 92%;
position: absolute;
top: 53%;
transform: translate(-50%, -50%);
text-align: center;
}

.dst
 {
   font-size: 14px;
 }

 .doxBox {
  background: #E8F1FC;
border: 0px solid #ffe10289;
/* box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.25); */
  padding: 10px 10px;
  border-radius: 10px; 
}

input[type=radio] {
  width: 17px;
  height: 17px;
}

.pkCon2 {
  background-color: #f2f2f2;
  padding: 5px 5px;
  border : 1px solid #f2f2f2;
  border-radius: 5px;
  font-family: 'Roboto','Lato';
  font-size: 0.75em;
  width: 270px;

}

.pkLocWidth {
  width: 40%;
}

.editBk {
  background-color: #e4f5ff;
  padding: 5px;

}