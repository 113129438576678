.not-head{
    font-family: 'Lato','Roboto';
    font-size: 16px;
    font-weight: 700;
}

.notCon{
    border: 1px solid rgba(0,0,0,0.15);
    padding: 15px 15px;
    /* background-color: #f2f2f2; */
    border-radius: 5px;
}