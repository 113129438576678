
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Roboto:wght@300;400&display=swap');
.SearchBox {
 
    height: 100vh;
    width: 100%;
    /* position: relative; */
  
}

.SearchHead {
background-color: #ffe205;
height:50px;
width: 100%;
/* position: absolute; */
top:0;
display: flex;
flex-direction: row;
/* justify-content: space-between; */
/* border-bottom: 3px solid rgba(0,0,0,0.1); */
padding: 10px 15px;

}

.SearchHeadNew {
    background-color:white;
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
   
}

.SeachCon{
    background-color: white;
          height:88vh;
          /* position: absolute; */
          width: 100%;
          bottom: 0;
          /* border-radius: 35px 35px 0px 0px; */
          display: flex;
          flex-direction: column;
          padding: 10px 20px 20px 20px;
        
}
.contentFont {
    font-family: 'Lato','Roboto';
    font-weight: 400;
    font-size: 18px;
   
  
}

.SearchBorder {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid rgba(0,0,0,0.4);
    padding:3px 10px;
    border-radius: 10px;
   border:none; 

}

.contentFont input{
    border: none;
    outline: none;
    width: 100%;
    font-size: 15px;
    color: rgba(0,0,0,0.45)
}


.SearchBar {
    
display: flex;
flex-direction: row;
padding:3px 12px;
border-radius: 20px;
border: 1px solid lightgray;
}

.SearchBar input {
    border:0;
    width: 100%;
    outline: none;
    background-color: white;
    font-size: 13px;
    color: red;
    font-weight: 700;
}

.SearIcon{
    cursor: pointer;
}

.AddNew {
   width: 100%;
    height: 20px;
    position: absolute; 
    bottom: 0;
    display: flex;
    flex-direction: row;
   justify-content: center;
   background-color: #ffe205;
   color: #000;
   padding: 6px 0px;
   font-size: 16px;
   font-family: 'Lato','Roboto';
   border: none;

}

.btn-primary :hover{
    color: #000;
}

.Search{
    cursor: pointer;
}

.SearchBar{
    display: flex;
    flex-direction: row;
    
}

.Searchbottom {
    height: 1px;
    width: 100%;
    background-color: #000;
    opacity: 0.1;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
    opacity: 0.5; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: black;
    opacity: 0.5; /* Firefox */
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: black;
    opacity: 0.5; /* Firefox */
  }

.SearchBarStyle input {
    color: #000;
}


