.trackId{
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 1em;
}

.trackNo{
    font-family: 'Lato';
    font-weight: 400;
    font-size: 1em;
}

.day{
    font-size: 0.75em;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
}

.date {
    font-size: 3.2em;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 41px;
}

.month {
    font-size: 0.8em;
    font-weight: 500;
    font-family: Arial, Helvetica, sans-serif;
}

.trackAnother{
    height: 50px;
    width: 250px;
    background-color: #ffe202;
    padding: 4px 10px;
    border-radius: 20px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}




.tracking-input {
    background: #FFFFFF;
    border: 2px solid black;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
   width: 70%;
   padding: 6px 30px;
   font-size: 18px;
}

.vr {
    height: 60px;
    width: 1px;
}


.topStatus {
    background-color: #008000;
    padding: 12px 10px;
}


/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {  
    img{
        height:12%;
        width: 60%;

    }
    .menu_bar{
        font-size: 12px;
      
    }
   
    .input_tracking {
        width: 100%;
        padding:8px;
        border:2px solid black;
    }
    .tracking-input {
        background: #FFFFFF;
        border: 2px solid black;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 50px;
       width: 100%;
       padding: 6px 30px;
       font-size: 18px;
    }
    
}
    

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) { 
    .input_tracking {
        width: 100%;
        padding:10px;
    }
    .tracking-input{
        width: 100%;
    }
    .input_tracking {
        width: 100%;
        padding:9px;
        border:0px;
    }
    
}
/* 
// Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
    .tracking-input{
        width: 100%;
    }
    .input_tracking {
        width: 100%;
        padding:9px;
        border:0px;
    }

     }

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
   .tracking_label{
       width: 100%;
       text-align: center;
   }

   .tracking-input {
    background: #FFFFFF;
    border: 1px solid black;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
   width: 80%;
   padding: 6px 30px;
   font-size: 18px;
}
.input_tracking {
    width: 100%;
    padding:10px;
    border:0px;
}
 }

.trackStat {
    background-color: #008000;
    border-radius: 1px;
    color: white;
}

.orgg{
    font-size: 0.75em;
    font-weight: 500;
    line-height: 10px;
    font-family: Arial, Helvetica, sans-serif;
}

.shipDate {
    font-size: 0.75em;
    font-weight: 500;
    line-height: 12px;
    font-family: Arial, Helvetica, sans-serif;

}

.shipHandle{
    font-size: 0.75em;
    font-family: 'Roboto','Lato';

}

.statCon{
    background-color: white;
    padding: 6px 7px;
}

.green {
    background-color: #008000;
    padding: 3px;
    /* width: 100%; */
}

.gray {
    background-color: gray;
    padding: 3px;
    /* width: 100%; */
}

.orange {
    background-color: #ffe202;
    padding: 3px;
    /* width: 100%; */
}

.penTxt {
    color:  #c01919;

}

.pending
{
    background-color: rgb(192, 25, 25);
    padding: 3px;
    /* width: 100%; */
}

.dateD{
    font-size: 0.85em;
    font-family: 'Lato','Roboto';
}

.stst{
    font-size: 700;
    font-family:Arial, Helvetica, sans-serif;

}

.txtOfd {
    color : #c4a809;
}

.txtGreen {
    color: #008000;
    font-weight: 700;
    font-family: Arial, Helvetica, sans-serif;
}

.time {
    font-size: 0.75em;
    line-height: 14px;
}

.expect{
    font-size: 1.2em;
    font-family:Arial, Helvetica, sans-serif;
    font-weight: 700;
}


.bgTracking {
    background-color: #e4f5ff;
   height: 240px;
   width: 100%;
   z-index: -1;
   position: absolute;
   transform: translate(0%,-50%);
   border-radius: 20px;
}

.trkBtn {
    background-color: #ffd500;
    padding: 15px 15px;
    border-radius: 0px 50px 50px 0px;
    font-size: 0.95em;
    font-family: 'Roboto', Helvetica, sans-serif;
    position:absolute;
    right:0;
}