.banner{
    width: 90%;
    height:10%;
}

.banner-left {
    float: left;
}

.banner-right {
    float: right;
}

.e-heading{
   
    border-bottom: 2px solid #FFE202;
    padding: 6px;
    margin: 0 0 -1px;
    display: inline-block; 
    margin-top: 2%;
    
   
}

.desc {
    font-weight: 300;
    color: gray;
    margin-top: 2%;
    line-height: 22px;
}

.cust-size{
    width: 75%;
}
