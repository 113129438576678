.impact{
    border-radius: 50px;
    padding-top: -15px;
}


.smartBox {
    height: 70%;
    width: 80%;
}


.smartHl {
    margin-top: 60px;
}

.impt-font{
    font-size: 14px;
    color: gray;
}

.ComingBtn{
    width: 30%;
    color: 0.95em;
    padding: 10px;
}
.coming-font {
font-size: 0.95em;
}

@media (min-width: 350px) { 
    .people{
        height: 60px;
        width: 60px;
        margin-left: 30px;
        margin-top: 10px;
    }
    
    .people-1{
        height: 45px;
        width: 45px;
        margin-right: 5px;
     
    }
    .cust-font{
        font-size: 18px;
        font-weight: normal;
        color: '#3F3D56';
    }

    .impact-marg{
        margin-bottom: 30px;
    }
    .impact-txt{
        font-size: 14px;
        color: gray;
        margin-top: 0px !important;
    }

    .smartBlg {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .smartBox {
        height: 70%;
        width: 50%;
    }

    .ComingBtn{
        width: 40%;
        color: 0.95em;
        padding: 10px;
    }
    .coming-font {
    font-size: 0.95em;
    }

}


@media (min-width: 576px) { 

    .people{
        height: 70px;
        width: 70px;
        margin-left: 30px;
        margin-top: 10px;
    }
    
    .people-1{
        height: 50px;
        width: 50px;
        margin-right: 10px;
     
    }
    .cust-font{
        font-size: 18px;
        font-weight: normal;
        color: '#3F3D56';
    }

      .smartBox {
        height: 70%;
        width: 50%;
        
    }
    .ComingBtn{
        width: 40%;
        padding: 15px;
    }
    .coming-font {
    font-size: 1.0em;
    }


 }

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
    .people{
        height: 80px;
        width: 80px;
        margin-left: 70px;
        margin-top: 20px;
    }
    
    .people-1{
        height: 55px;
        width: 55px;
        margin-right: 10px;
     
    }

    .cust-font{
        font-size: 22px;
        font-weight: normal;
        color: '#3F3D56';
    }

    .smartBox {
        height: 70%;
        width: 80%;
    }
    .ComingBtn{
        width: 50%;
        padding: 15px;
    }
    .coming-font {
    font-size: 1.0em;
    }


 }

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 

    .people{
        height: 100px;
        width: 100px;
        margin-left: 70px;
        margin-top: 20px;
    }
    
    .people-1{
        height: 70px;
        width: 70px;
        margin-right: 10px;
     
    }

    .cust-font{
        font-size: 22px;
        font-weight: normal;
        color: '#3F3D56';
    }

    .smartBox {
        height: 70%;
        width: 80%;
    }

    .ComingBtn{
        width: 40%;
        padding: 15px;
    }
    .coming-font {
    font-size: 1.0em;
    }


 }

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 

    .people{
        height: 100px;
        width: 100px;
        margin-left: 70px;
        margin-top: 20px;
    }
    
    .people-1{
        height: 70px;
        width: 70px;
        margin-right: 10px;
     
    }

    .cust-font{
        font-size: 22px;
        font-weight: normal;
        color: '#3F3D56';
    }
    
    .impact-txt{
        font-size: 17px;
        color: gray;
        margin-top: 0px !important;
        padding: 0px 50px;
    }
    .smartBox {
        height: 70%;
        width: 80%;
    }

    .ComingBtn{
    width: 40%;
    padding: 15px;
}
.coming-font {
font-size: 1.0em;
}


 }

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) { 

    .people{
        height: 100px;
        width: 100px;
        margin-left: 70px;
        margin-top: 20px;
    }
    
    .people-1{
        height: 70px;
        width: 70px;
        margin-right: 10px;
     
    }

    .cust-font{
        font-size: 22px;
        font-weight: normal;
        color: '#3F3D56';
    }

    .impact-txt{
        font-size: 18px;
        color: gray;
        margin-top: 0px !important;
        padding: 0px 50px;
    }
    .smartBox {
        height: 70%;
        width: 80%;
    }

    .ComingBtn{
        width: 50%;
        padding: 15px;
    }
    .coming-font {
    font-size: 1.2em;
    }

  
 }