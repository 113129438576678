
.qty {
    width: 10%;
    text-align: center;
    border: none;
        border-radius: 2px;
        background-color: white;
        width: 20px;
}   

.inc{
       background-color: transparent;
       color: black; 
       border: none;
       padding:2px 5px;
       border-radius: 5px;
       margin: 0px 5px;
       font-size: 0.85em;
}
.fontOrder{
    font-size: 0.9em;
    color: black;
    font-family: 'Lato','Roboto';
    letter-spacing: 0.02em;
    padding-top:3px;
}

.addother{
    border: 1px dotted black;
    background-color: white;
    border-radius: 5px;
    height: 40px;
}

.addBorder{
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: 4px;
}


