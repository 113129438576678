@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,600;0,800;1,700&display=swap');
*{
   

    font-family: 'Poppins', sans-serif;
}

.head1{
    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 60px;
    
    /* identical to box height */
    text-align:left;
    letter-spacing: 0.04em; 
    color: #424242;
}

.head2{
   
font-family: Poppins;
font-style: normal;
font-weight: 600;
font-size: 40px;
line-height: 20px;

/* identical to box height */
text-align: left;
letter-spacing: 0.04em;

color: #000000;

}

.org {
    margin: 0 0.4em;
  padding: 4px!important;
  border:1px solid #d3232e;
}

.input_pick {
    background: #FFFFFF;
border: 1px solid rgba(0, 0, 0, 0.15);
box-sizing: border-box;
border-radius: 5px;
padding: 8px 15px;
width: 100%;

}

textarea:focus, input:focus{    
    outline: none;
   
}
.input_sizs {
  border: 0;
    width: 100% ;
}

.trans {
    border-left: 1px dashed gray;
  height: 40px;
  margin: 2px 0px;
}

.service {
    margin-top: 20px;
    background-color: #fff;
    width: 100%;
    height:19px;
    padding:0px;
    font-size: 18px;
    color:black
}

.topnav {
   
    background-color: rgba(255, 255, 255, 0);
    height: 47px;
 
  }
  
  .topnav li {
   
    
    color: black;
    text-align: center;
    
    text-decoration: none;
    font-size: 17px;
    border-bottom: 3px solid transparent;
  }
  
  .topnav li:hover {
    border-bottom: 4px solid #ffe202;
    
  }
  
  .topnav li.active {
    border-bottom: 4px solid #ffe202;

  
  }

.sel-btn{
  padding: 10px 40px  ;
  background-color: #ffe202;
}
 

.shipping-ser{
  width: 90%;
  height: 36px;
}

.cardPickup{
  background: #FFFFFF;
border: 1px solid rgba(0, 0, 0, 0.02);
box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.25);
border-radius: 20px;
  background-color: white;
  padding: 5px;
  border-radius: 10px;
  padding-right: 20px;
}



.shipT {
  font-family: 'Roboto';
  font-size: 0.75em;

}

.pik-bk{
  border-radius: 15px;
  background-color: #F2FBF7;
}

.edits {
  font-size: 0.70em;
  font-family: 'Roboto';
  font-weight: 400;
  background-color: black;
  border-radius: 5px;
  padding: 3px 2px;
  color: white;
}

.routee {
  font-family: 'Lato','Roboto';
  font-weight: 700;
  font-size: 0.95em ;
}

.mobIn {
  padding-left: 28px;
}

.bgGreen {
border: 1px solid #008000;
}

.checkIndex {
  z-index: 10;
  background-color: white;
  border-radius: 50%;
  height: 50px;
  width: 50px;
}

.btnlogin {
  background-color: #e4f5ff;
  border-radius: 5px !important;
}

.btnlogin1 {
  background-color: #fff7d9;
  border-radius: 5px !important;
}

.btnlogin2 {
  background-color: #f2f2f2;
  border-radius: 5px !important;
}


.telPhone {
  text-decoration: none;
}