.input-border {
    background: #FFFFFF;
border-bottom: 1px solid rgba(151, 151, 151, 0.3);
box-sizing: border-box;
align-items: center;
padding: 5px 0px;
margin-left: 3%;
margin-right: 3%;
margin-bottom: 20px;
font-size: 14px;
font-family: 'Lato','Roboto';
}

.input-style{
    border: 0;
    width: 70%;
    font-size: 16px;
}

.floatingLbl{
    background-color: white;
    padding: 1px 2px;
    transform: translate(12%,-50%);
    position: absolute;
    z-index: 3;
   font-size: 14px;
   font-family: 'Lato','Roboto';
}
.ext{
    font-size: 14px;
    font-family: 'Lato','Roboto';
}
input + label {
    color: gray;
    font-family: 'Lato','Roboto';
    font-size: 15px;

  }
  input:focus + label {
    color: #000;
  }
.form-control:focus {
  border-color: #ffe202;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 226, 2, 0.6);
  }
  
  .form-control
  {
     /* border-radius: 0px; */
     /* border: none; */
    
     /* border-bottom: 1px solid gray; */
    
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
 
  
  
  
  
  
  