
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Mulish:wght@200&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,600;0,800;1,700&display=swap');

.q-service{
  width: 100%;
  height: 36px;
margin-top: 5px;
}

.BookingInput{
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  width: 100%;

}

.TranIcon{
display: flex;
flex-direction: column;
padding:5px;
align-items: center;
}

.TranLine{
  border-left: 1px dashed black;
  height: 30px;
  margin: 2px 0px;
}

.TranLine2{
  border-left: 1px dashed black;
  height: 40px;
  margin: 2px 0px;
}

.BookingTextBox{
  background-color: transparent;
  display: flex;
  flex-direction: column;
 width: 100%;
position: relative;
height: 100%;
}

.InputStyle {
 
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.35);
  box-sizing: border-box;
  border-radius: 5px;
  padding: 5px 15px;
  /* width: 100%; */
margin-bottom: 17px;
margin-left: 10px;
}

.InputStyle1 {
 
  background: #FFFFFF;
  border: 1px solid rgb(197, 17, 17);
  box-sizing: border-box;
  border-radius: 5px;
  padding: 5px 15px;
  /* width: 100%; */
margin-bottom: 17px;
margin-left: 10px;
}

.TxtInput{
  border: none;
  font-size: 15px;
  font-family: 'Lato';
  width: 100%;
}



.Rev {
  display: flex;
  background-color: black;
  position: absolute;
 height:35px;
 width: 35px;
 justify-content: center;
 align-items: center;
  border-radius: 50%;
right:0;
transform: translate(-30%,80%);
cursor: pointer;


}

.Rev-btn {
  animation-duration:2s;
  animation-name: dim;
  animation-iteration-count: 1;
}

.pkPoint {
  
  animation-duration: 0.5s;
animation-name: replace;
animation-fill-mode: forwards;
}

.pkStatic {
  transform: translateY(147%);
}

.pkRevStatic {
  transform: translateY(0%);
}

.dpStatic {
  transform: translateY(-147%);
}

.dpRevStatic {
  transform: translateY(0%);
}
.pkPoint-rev {
  
  animation-duration: 0.5s;
animation-name: pk-backward;
animation-fill-mode: forwards;
}


.drPoint {
  
  animation-duration: 0.5s;
animation-name: Replace1;
animation-fill-mode: forwards;
}


.drPoint-rev {
  
  animation-duration: 0.5s;
animation-name: drop-backward;
animation-fill-mode: forwards;
}

/* What do you want to deliver  */

.WhatCon{
  display: flex;
  flex-direction: column;
  margin-left:10px;
 background-color: aqua;
 height: auto;

}
.whatTop{
  background-color: #f2f2f2;
  font-family: 'Roboto';
 border-radius: 8px;
 padding: 8px 15px;
 font-size: 14px;
}

.SerCont {
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-top: 20px;
 justify-content: space-between;
 
}

.SelectedInput{

  display: flex;
  flex-direction: row;
  width: 100%;
}

@keyframes  replace 

{
 0% {  
   
transform: translateY(0%);

}
100% {
  transform: translateY(147%);
}
}


@keyframes  pk-backward 

{
 0% {  
   
transform: translateY(147%);

}
100% {
  transform: translateY(0%);
}
}

@keyframes  Replace1

{
 0% {  
   
transform: translateY(0%);

}
100% {
  transform: translateY(-147%);
}
}


@keyframes  drop-backward

{
 0% {  
   
transform: translateY(-147%);

}
100% {
  transform: translateY(0%);
  
}
}

@keyframes dim {
  0% {opacity: 0;
  pointer-events: none;
  }
  100% {opacity: 1;}
}

.packType{
  position: absolute;
  transform: translate(0%,550%);
  font-size: 0.75em;


}

.errLabel input::-webkit-input-placeholder {
 
  color: rgb(148, 4, 4);

}

.StoreAdd{
  font-size: 0.75em;
  font-weight: 500;
  margin-top: 10px;
  padding-left: 5px;
  color: rgba(0, 0, 0, 0.85);
}

.pickupCard2{
  background: transparent;
   border: 1px solid rgba(151, 151, 151, 0.4);
  border-radius: 10px;
  width: 95%;
  height:55px;
  }

  .RecMob{
   
    background: white;
border: 1px solid rgba(151, 151, 151, 0.4);
border-radius: 10px;
width: 95%;
height:50px;
  }

.RecMobErr{
   
  background: white;
border: 1px solid rgba(233, 10, 10,0.9);
border-radius: 10px;
width: 95%;
height:50px;
}

  .mobStd{
    font-size: 0.85em;

  }

  .mobTop{
    margin-top: 12px;
  }

  .RoundRupee {
    padding: 3px;
    background-color: #0d1740;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .twoLine {
    display: -webkit-box;
    max-width: auto;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .oneLine {
    display: -webkit-box;
    max-width: auto;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  /* *****************************PWA CSS CLASS************************************** */

  .add-to-home-screen-pwa {
    display: table;
    position: fixed;
    text-align: center;
    z-index: 1000;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 10px;
    width: 100%;
    bottom: 0;
    box-shadow: 0 -4px 4px rgb(0 0 0 / 4%);
    border: 1px solid #cecece;
    cursor: pointer;
    color: #000;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='50.0' height='50.0' viewBox='0 0 40.0 100.0'><path opacity='.6' fill='black' d='M35.6 34.4L28 26.8l7.6-7.6c.2-.2.2-.5 0-.7l-.5-.5c-.2-.2-.5-.2-.7 0l-7.6 7.6-7.5-7.6c-.2-.2-.5-.2-.7 0l-.6.6c-.2.2-.2.5 0 .7l7.6 7.6-7.6 7.5c-.2.2-.2.5 0 .7l.5.5c.2.2.5.2.7 0l7.6-7.6 7.6 7.6c.2.2.5.2.7 0l.5-.5c.2-.2.2-.5 0-.7z'/></svg>") no-repeat right top #fff;
  }
  
  .add-to-home-screen-pwa__notify {
    display: table-cell;
  }
  
  .add-to-home-screen-pwa__notify p {
    font-size: inherit;
    text-rendering: optimizeLegibility;
  }
  
  .add-to-home-screen-pwa__notify p:first-of-type {
    margin-top: .7rem;
  }
  
  .add-to-home-screen-pwa__notify p.heading {
    font-weight: 600;
  }
  
  .add-to-home-screen-pwa__notify .home, .share {
    background-repeat: no-repeat;
    background-size: cover;
    width: 1.25em;
    height: 1.25em;
    display: inline-block;
    vertical-align: text-bottom;
  }
  
  .add-to-home-screen-pwa__notify .home {
    background-image: url("data:image/svg+xml,<svg height='300' width='300' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'><switch><g><path d='M81 2.5H19C9.9 2.5 2.5 9.9 2.5 19v62c0 9.1 7.4 16.5 16.5 16.5h62c9.1 0 16.5-7.4 16.5-16.5V19c0-9.1-7.4-16.5-16.5-16.5zm-3.2 51.7H54.2v23.6c0 2.3-1.9 4.2-4.2 4.2-1.2 0-2.2-.5-3-1.2-.8-.8-1.2-1.8-1.2-3V54.2H22.2c-1.2 0-2.2-.5-3-1.2-.8-.8-1.2-1.8-1.2-3 0-2.3 1.9-4.2 4.2-4.2h23.6V22.2c0-2.3 1.9-4.2 4.2-4.2 2.3 0 4.2 1.9 4.2 4.2v23.6h23.6c2.3 0 4.2 1.9 4.2 4.2.1 2.3-1.8 4.2-4.2 4.2z'/></g></switch></svg>");
  }
  
  .add-to-home-screen-pwa__notify .share {
    background-image: url("data:image/svg+xml,<svg height='300' width='300' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'><path d='M79.636 28.404H59.515a2.5 2.5 0 1 0 0 5H77.636v55.271H22.364V33.404h18.509a2.5 2.5 0 0 0 0-5H20.364c-1.65 0-3 1.35-3 3v59.271c0 1.65 1.35 3 3 3h59.271c1.65 0 3-1.35 3-3V31.404c.001-1.65-1.349-3-2.999-3z'/><path d='M34.761 23.297l12.801-9.126v46.96a2.439 2.439 0 0 0 4.878 0V14.17l12.802 9.126a2.736 2.736 0 0 0 3.822-.641 2.74 2.74 0 0 0-.641-3.823l-16.832-12a2.74 2.74 0 0 0-3.182 0l-16.832 12a2.741 2.741 0 1 0 3.184 4.465z'/></svg>");
  }
  
  .add-to-home-screen-pwa__notify button {
    display: inline-block;
    background-color: #c86b60;
    color: #fff;
    text-transform: uppercase;
    border: 1px solid transparent;
    border-radius: 8px;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    letter-spacing: 0.1em;
    padding: 1.1em 3.134em;
  }
  

  .bgTrans{
    background-color: transparent;
  }