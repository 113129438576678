.sel-pdt{
    position:absolute;
    transform: translate(-50%,-50%);
    top:18%;
    left:77%;
  background-color: #fff;
  border-radius: 50%;
    cursor: pointer;
}

.SerFont {
  font-family: 'Roboto';
  font-size: 14px;
}

.Return{
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: black;
  color: white;
right :0;
top:0;
display: flex;
justify-content: center;
align-items: center;
border-radius: 50%;
font-size:13px;
padding-bottom: 1px;
cursor: pointer;
}

.scroll-h {
  overflow:scroll;
  width: 100%;
    -webkit-overflow-scrolling: touch;
}
.quote{
 margin-top: 9px;

 /* margin-bottom: 2px; */
 background-color: #ffe202;
 border: none;
 border: 1px solid #ffe202;
 /* width: 100%; */
 border-radius: 5px;
 padding: 8px 5px;
 font-size: 0.95em;
 text-align: center;
}