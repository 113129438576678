.plsWait{
    background-color: rgba(0,0,0,0.1);
    position: absolute;
    height: 100vh;
    width: 100%;
    top:0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.idProof{
    transform: rotate(0deg);
    width: 100%;
    height: 200px;
    z-index: 10;
   
}

.kycBorder{
    width: 98%;
    height: 160px;
    border: 1px solid rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* z-index: 10; */
    background-color: transparent;
    position: absolute;
    border-radius: 5px;
}
.choose{
    display: flex;
    position: absolute;
    /* z-index: -10; */
}

.kycSize{
    height:160px;
    width: 90vw;
}

.imageLoader {
    display: inline-block;
    background-color: #b0c0c7;
    animation-name: shine;
    animation-duration: 2.4s;
    animation-iteration-count: infinite;
    width: 100%;
    height:160px;
    
  }
  
  @keyframes shine {
    0% { 
      opacity: 1;
    }
    50% {
      opacity: 0.35;
    }
    100% {
      opacity: 1;
    }
  }

  .upload{
      width: 91vw ;
      height:160px;
       
          /* position: absolute;
          top:0; */
         /* transform: translate(-50%,60%); */ 
           /* z-index: -10; */
  }


.prgress{
  width: 90vw;
  background-color: rgba(0,0,0,0.1);
  height: 160px;
  padding:0px 20px;
 position: absolute;
bottom:0;
/* display: flex;
flex-direction: column;
align-items: center;
justify-content: center; */
}

.foodCnt {
  height: 70px;
  width: 100%;
  border: 1px solid black;
  border-radius: 10px;
  background-color: white;
  display: flex;
  align-items: center;
 
}

.foodImg{ 
  height: 50px;
  width: 50px;
  border-radius: 50%;
  /* border: 2px solid #0d1740; */
  background-color: white;
}

.bgYellow{
  background-color: #e4f5ff;
  border-radius: #e4f5ff;
}