.btmMsg {
    border-radius: 30px;
   background-color: black;
 animation-name: tooltip;
 font-size: 0.85em;
 font-family: 'Lato';
    animation-duration: 3.4s;
    animation-fill-mode: forwards;
    
  }
  
  @keyframes tooltip {
    0% { 
      opacity: 1;
    }
   
    100% {
      opacity: 0;
    
  }
}