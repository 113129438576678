.font-famimy{
    font-family: 'Lato','Roboto';
}

.check-name{
    font-family: 'Lato','Roboto';
    font-size: 0.8em;
    line-height: 18px;
    
    color:#000;
    font-weight: 700;
}

.check-add{
    font-family: 'Lato','Roboto';
    font-size: 0.75em;
    line-height: 18px;
    color: rgba(70, 68, 68,0.7);
}

.check-city{
    font-family: 'Lato','Roboto';
    font-size: 0.8em;
    line-height: 20px;
    color: rgba(70, 68, 68,1);
}

.check-person {
    font-family: 'Lato','Roboto';
    font-size: 0.76em;
    line-height: 20px;
    color: rgba(70, 68, 68, 0.685);
}

.col-rate{
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 2px 5px;
}

.check-service{
    border: 1px solid rgba(0,0,0,0.2);
   font-size: 0.8em;
   width: 85px;
   padding: 0px 0px;
   display: flex;
   align-items: center;
  justify-content: space-between;
}

.check-rate{
    font-family: 'Lato','Roboto';
    font-size: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
}

.divider {
    height: 5px;
    background-color: #E5E5E5;
}
.check-heading{
    font-size: 1em;
    font-family: 'Lato','Roboto';
    padding: 2px 20px;
}

.check-pk{
   
    padding: 2px 15px;

}

.pk-caption{
   font-weight: 700;
    font-family: 'Lato','Roboto';
    font-size: 0.8em;
}

.pk-con{
    background-color: #ffe10228;
    padding: 4px 5px;
    margin: 2px 0px;
    border-radius: 15px;
    width: 100%;
}

.pk-add
{
    font-family: 'Lato','Roboto';
    font-size: 0.8em;
    line-height: 18px;
    color: rgba(0,0,0,0.7);
    padding-top: 5px;
    
}

.chg-btn{
background-color: #ffe202;
color: black;
height: 30px;
width: 70px;
display: flex;
justify-content: center;
align-items: center;
border-radius: 10px;
font-size: 0.7em;
padding:2px 5px;
margin-right: 4px;
}

.ads{
    width: 95%; 
}

.chekLoc {
    height: 35px;
    width: 35px;

}

.promo{
    margin-top: 14px;
    width: 96%;
    padding: 2px 15px;
    background: #EBECF1;
    padding: 6px 15px;
    border-radius: 5px;
    border:none;
    margin-bottom: 10px;
}
.promo-input{
    width: 80%;
    border: none;
    background-color: transparent;
    font-size: 0.9em;
}

.apply{
    font-family: 'Lato','Roboto';
    font-size: 0.85em;
}

.small-f {
    font-family: 'Lato','Roboto';
    font-size: 14px;
    line-height: 14px;
}

.total {
    font-family: 'Lato','Roboto';
    font-size: 1.0em;
    line-height: 14px;
    font-weight: 700;
}

.pamentBtm {
    padding-bottom: 70px;
}

.payment-btn {
    background-color: black;
    padding:8px 15px;
    width: 100%;
    color:white;
    /* border-radius: 5px; */
    display: flex;
    font-size: 18px;
    justify-content: center;
    margin-top: 10px;
}

.payment-btn1 {
    background-color: white;
    border: 1px solid black;
    padding:8px 15px;
    width: 100%;
    color:black;
    /* border-radius: 5px; */
    display: flex;
    font-size: 18px;
    justify-content: center;
    margin-top: 10px;
}

.personWdith{
    width: 100px;
}

.payDetail{
    font-size: 0.9em;
    font-family: 'Lato','Roboto';
}

.deleteAlert{
 
}

.deleteHad{
    font-size: 1em;
    font-family: 'Lato','Roboto';
}

.avtar-card2{
    float: left;
    width: 120px;
    height: 48px;
    margin-right: 12px;
    margin-bottom: 5px;
    border-radius: 10px;
  }
  .placeholder3 {
    display: inline-block;
  border: 1px solid #b0c0c7;
    height: 40px;
    width: 50%;
    animation-name: shine;
    animation-duration: 2.4s;
    animation-iteration-count: infinite;
  }

  .placeholder2 {
    display: inline-block;
    background-color: #b0c0c7;
    height: 40px;
    width: 50%;
    animation-name: shine;
    animation-duration: 2.4s;
    animation-iteration-count: infinite;
  }


  .checkHead{
      height: 50px;
    background-color: #ffe202;
    padding: 12px 0px
  }

  .paymentHT{
      height: 35%;
  }

  .paymentHT1{
    height: 25%;
}

  .ads{
      height: 20%;
  }

  .outFP{
      height: 150px;
      width: 100%;
  }

  .paymentOption {
      background-color: #f2f2f2;
      width: 95%;
      padding: 10px 6px;
      font-size: 0.95em;
      font-family: 'Lato';
  }
  .locRound {
      background-color: #ffe202;
      height: 30px;
      width: 30px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
     padding: 6px;
  }
  .checkRate {
  
    width: 20%;
  }

  .btnIndex {
    z-index: -1;
  }
  
  .checkAddCon {
    width: 72%;
  }

  .oninePay {
    background-color: #1c916e;
    border-radius: 10px;
    padding: 7px 10px;
    width: 95%;
    color:white;
  }


@media (min-width: 375px) { 
    .checkScroll {
        height: 84vh;
        overflow-y: auto;
        /* background-color: #1c916e; */
      }
}

@media (min-height: 575.98px) { 
    .checkScroll {
        height: 84vh;
        overflow-y: auto;
        /* background-color: #1c916e; */
      }
}



/* // Small devices (landscape phones, 576px and up) */
@media (min-height: 576px) and (max-height: 767.98px) {
    .checkScroll {
        height: 85.5vh;
        overflow-y: auto;
        /* background-color: #1c916e; */
      }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-height: 768px) and (max-height: 991.98px) { 
    .checkScroll {
        height: 88.5vh;
        overflow-y: auto;
        /* background-color: #1c916e; */
      }
}

/* // Large devices (desktops, 992px and up) */
@media (min-height: 992px) and (max-height: 1199.98px) { 
    .checkScroll {
        height: 91vh;
        overflow-y: auto;
        /* background-color: #1c916e; */
      }
}

/* // Extra large devices (large desktops, 1200px and up) */

@media (min-height: 600px) {
    .checkScroll {
        height: 82vh;
        overflow-y: auto;
        /* background-color: #1c916e; */
      }
}

@media (min-height: 667px) {
    .checkScroll {
        height: 84vh;
        overflow-y: auto;
        /* background-color: #1c916e; */
      }
}

@media (min-height: 720px) {
    .checkScroll {
        height: 85.2vh;
        overflow-y: auto;
        /* background-color: #1c916e; */
      }
}

@media (min-height: 800px) {
    .checkScroll {
        height: 86.5vh;
        overflow-y: auto;
        /* background-color: #1c916e; */
      }
}

@media (min-height: 844px) {
    .checkScroll {
        height: 87.5vh;
        overflow-y: auto;
        /* background-color: #1c916e; */
      }
}

@media (min-height: 851px) {
    .checkScroll {
        height: 87.5vh;
        overflow-y: auto;
        /* background-color: #1c916e; */
      }
}

@media (min-height: 896px) {
    .checkScroll {
        height: 88.3vh;
        overflow-y: auto;
        /* background-color: #1c916e; */
      }
}


@media (min-height: 914px) { 
    .checkScroll {
        height: 88.5vh;
        overflow-y: auto;
        /* background-color: #1c916e; */
      }
}

@media (min-height: 1024px) {
    .checkScroll {
        height: 89.7vh;
        overflow-y: auto;
        /* background-color: #1c916e; */
      }
}

@media (min-height: 1180px) {
    .checkScroll {
        height: 91vh;
        overflow-y: auto;
        /* background-color: #1c916e; */
      }
}

@media (min-height: 1368px) {
    .checkScroll {
        height: 92.3vh;
        overflow-y: auto;
        /* background-color: #1c916e; */
      }
}