
.pickup-point {

background: white;
box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
border-radius: 35px 35px 0px 0px;
height: 10%;
}
.pack-type{
    font-size: 14px;
}


.red-notificaton {
background: darkred;
z-index: 100;
padding: 4px;
border-radius: 50%;
mix-blend-mode: hard-light;
margin-left: -7px;
position: absolute;
transform: translate(-50%,-50%);
top:35%;
left:87%;
}

.pick-drop{
    margin-top: 30px;
}

.menu-barr{
    background: #FFFFFF;
mix-blend-mode: hard-light;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
padding: 5px 9px;
border-radius: 20px;
position: absolute;
margin-left: 7px;
top:10px;
}

.bell-not{
background: #FFFFFF;
mix-blend-mode: hard-light;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
padding: 5px 9px;
border-radius: 20px;
position: absolute;
top:10px;
z-index: 3;
}

.map {
    
    position: relative;
    width: 100%;
}


*{
    padding: 0px;
    margin:0px;
   
}

.est-input {
   
        border: 0;
        width: 70%;
        font-size: 16px;
        color: #000;
 
}

.est-wgt {
    background: #FFFFFF;
opacity: 0.4;
border: 1px solid rgba(151, 151, 151, 0.4);
box-sizing: border-box;
border-radius: 10px;
}

.trans-box {
    border-left: 1px dashed white;
  height: 50px;
  margin: 2px 0px;
}

.cursor{
    cursor: pointer;
}



@media (min-width: 250px) { 

    .pickup-block {
display: none;

    }  
    .pickup-point { 
        width: 100%;
    }
    .rotate {
        display: none;
    }
    .bell-not{
        left:89%;
    }
}

.what-dele{
    margin-left: 20px;
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) { 
    .pickup-block {
        display: block;
            }  
            .booking-scr{
                display: none;
            }
            .rotate {
                display: block;
            }
           
 }

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 

    .pickup-block {
        display: none;
            }  
            .booking-scr{
                display: block;
            }
            .rotate {
                display: none;
            }
           
 }

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 

    .pickup-block {
        display: block;
            }  
            .pickup-point {
                width: calc(100% / 3.07);
            }
            .booking-scr{
                display: block;
            }

            .rotate {
                display: none;
            }

        .bell-not{
            left: calc(100% / 3.5);
        }
            

 }

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 

    .pickup-block {
        display: block;
            }
            .pickup-point {
                width: calc(100% / 3.055);
            }
            .booking-scr{
                display: block;
            }
            .rotate {
                display: none;
            }
            .bell-not{
                left: calc(100% / 3.5);
            }
 }

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) { 
    .pickup-block {
        display: block;
            }

.pickup-point {
    width: calc(100% / 3.055);
}
.booking-scr{
    display: block;
}
.rotate {
    display: none;
}

.bell-not{
    left: calc(100% / 3.5);
}
 }



.Map {
    height: 45vh;
    background-color: darkcyan;
   
}
.Booking1 {
    height: 65vh;
    position: absolute;
    bottom:0;
    background-color: aqua;

}
.BookBox1 {
    background-color: red;
       /* position: absolute; */
       height: 64vh;
       bottom: 0;
      }

      .HomeContainer {
          background: white;
          height: 100%;
          width: 100%;
         display: flex;
         flex-direction: column;
            justify-items: flex-end;
      }

      .MapContainer{
          background-color: #8ab4f8;
          height:100%;
          width: 100%;
        
          /* position: absolute; */
      }

      .EbookingCont {
          background-color: #fff;
          height: 430px;
          /* position: absolute; */
          width: 100%;
         bottom:0 ;
          border-radius: 35px 35px 0px 0px;
          display: flex;
          flex-direction: column;
          padding: 20px 20px 0px 20px;
        transform: translate(0%,-12%);

      }


  .domestic{
      background-color: #ffe1027c;
  }

  .sideCon {
      background-color: transparent;
      height: 100%;
      position: absolute;
      top:0;
      width: 100%;
  }

  .sideBar {
    background-color: #fff;
    height: 100%;
    position: absolute;
    top:0;
    width: 70%;
  }

  .dashBoardLoader {
    display: inline-block;
    background-color: #b0c0c7;
    animation-name: shine;
    animation-duration: 2.4s;
    animation-iteration-count: infinite;
    width: 100%;
    height:230px;
    border-radius: 10px;
  }
  
  @keyframes shine {
    0% { 
      opacity: 1;
    }
    50% {
      opacity: 0.35;
    }
    100% {
      opacity: 1;
    }
  }

.mainHide
{
    position: absolute;
    /* top:0; */
    /* left:0; */
    /* z-index: -100; */
    /* height : 0px; */
}

.polyFit{
    /* position: absolute; */
    /* transform: translate(0%,-20%); */
    background-color: #000;
}