.header-login {
background-color: #FFE202;
border-radius: 20px 20px 0px 0px;
}

.login-img{
    padding: 3% 18%;
}

*{
    background-color:"gray ";
}

.login-col {
    background-color: 'red';
}

.forgot {
    font-size: 12px;
}
.new-cust-f{
    font-size: 12px;
}

.login-head{
    border-radius: 10px;
    padding: 4px 0px;
    margin-bottom: 20px;
    display: flex;
  justify-content: flex-start;
    align-items: center;
    position: relative;
}

.login-wel {
    position: absolute;
    transform: translate(0%,0%);
    display: flex;
    width: 100%;
    justify-content: center ;
    z-index: -2;
  
}

.login-bk{
    padding:2px 2px;
    border-radius: 50%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 10;
}

.err {
    font-size: 14px;
}

.visible{
    position: absolute;

right:0;
    transform: translate(-100%,-170%);
 
   

}

.logibBk {
    background-color: #f2f2f2;
}