.amp-ht {
    height: 60vh;
}
.marker-pos{
    position: absolute;
    top:0;
   width: 100%;
    height: 100%;
    transform: translate(0%,43%);

}

.notificationCon {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 70%;
    padding: 10px 7px;
    height: 50px;
    display: flex;
    align-items: center;
 
}

.notificationCon1 {
    background: #ffe1024d;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 100%;
    padding: 10px 15px;
    height: 60px;
    display: flex;
    align-items: center;
 
}

.ParcelBox{
    height: 40px;
    width: 40px;
    padding: 10px 10px;
    background-color: #ffe202;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-items: center;
}

.ParcelBox1{
    height: 40px;
    width: 40px;
    padding: 5px 5px;
    background-color: #ae361c;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-items: center;
}

.empImg {
    height: 58px;
    width: 55px;
background-color: #ffe202;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-items: center;
    padding:0;
    border:none;
}

.Heading{
    font-family: 'Lato','Roboto';
    line-height: 12px;
    font-weight: 700;
    font-size: 0.95em;
}

.small-ft{
    font-size: 12px;
    font-family: 'Lato','Roboto';
    line-height: 15px;
    font-weight: 500;
    padding-top: 5px;
    color: rgba(0, 0, 0, 0.9 );
}

.trc{
    background-color: #ffe202;
    padding:3px 10px;
    border-radius: 5px;
}

@media (min-width: 350px) { 
    .trc{
        background-color: #ffe202;
        padding:3px 3px;
        border-radius: 5px;
        font-size: 0.65em;
    }

   
}

@media (max-width: 575.98px) { 
    .trc{
        background-color: #ffe202;
        padding:3px 6px;
        border-radius: 5px;
        font-size: 0.85em;
        font-family: 'Lato';

    }
 }

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
    .trc{
        background-color: #ffe202;
        padding:3px 10px;
        border-radius: 5px;
        font-size: 0.95em;
        font-family: 'Lato';
    }
    
  }

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) { 
    .trc{
        background-color: #ffe202;
        padding:3px 10px;
        border-radius: 5px;
        font-size: 0.95em;
        font-family: 'Lato';
    }
 }

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) { 
    .trc{
        background-color: #ffe202;
        border-radius: 5px;
        font-size: 0.95em;
        font-family: 'Lato';
    }
 }

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
    .trc{
        background-color: #ffe202;
        padding:3px 10px;
        border-radius: 5px;
        font-size: 0.95em;
        font-family: 'Lato';
    }
    }

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) { 
    .trc{
        background-color: #ffe202;
        padding:3px 10px;
        border-radius: 5px;
        font-size: 0.95em;
        font-family: 'Lato';
    }
 }



.empProfile {
    display: inline-block;
    background-color: #b0c0c7;
    animation-name: shine;
    animation-duration: 2.4s;
    animation-iteration-count: infinite;
    width: 100%;
    height:50px;
    
  }
  
  @keyframes shine {
    0% { 
      opacity: 1;
    }
    50% {
      opacity: 0.35;
    }
    100% {
      opacity: 1;
    }
  }

