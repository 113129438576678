.MapHead{
   position: absolute;
  height: 20px;
    top: 15px;
    width: 100%;
    padding:10px;
  
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.GoogleCon {

height: 55vh;
background-color: transparent;
}
.Bar1 {
   
    background: #FFFFFF;
    /* mix-blend-mode: hard-light; */
    box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.25);
   width: 36px;
   height: 36px;
   border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  margin-left: 10px;
  cursor: pointer;
}

.Bell1 {

   
    background: #FFFFFF;
mix-blend-mode: hard-light;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
position: relative;
cursor: pointer;
width: 36px;
height: 36px;
border-radius: 50%;
display: flex;
justify-content: center;
align-items: center;
margin-right: 10px;
}   

.red-notificaton1 {
   background-color: darkred;
   width: 8px;
   height: 8px;
   border-radius: 50%;
   position: absolute;
   transform: translate(80%, -60%);
 
    }

    #map {
      width: 100%;
     height: 50vh;
      }