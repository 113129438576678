.img-size {
    height:90%;
    width: 90%;
    margin-top: 20px;
   margin-left: 12%;
 }

.sliderCap {
    font-weight: 600;
    font-size: 2.3em;
    font-family: 'Inter', sans-serif;
}