.Sidebar-h{
    font-family: 'Lato','Roboto';
    font-size: 14px;
    color: rgba(0,0,0,0.5);
    line-height: 14px;
font-weight: 500;
}

.meneCon{
    padding-top: 100px;
    padding-left:15px;
}

.menuFnt{
    font-family: 'Lato','Roboto';
    font-size: 18px;
    color: #000;
    letter-spacing: 0.03em;
}

.menuFnt1{
    font-family: 'Poppins','Roboto';
    font-size: 18px;
    color: #000;
    letter-spacing: 0.03em;
    font-weight: 300;
}

.divide{
    opacity: 0.2;
border: 1px solid #898A8D;
}

.TrackBd {
    border: 1px solid black;
    padding: 4px 10px;
    width: 100%;
}

.TrckBtn {
    background-color: black;
    width: 100%;
    height: 10%;
    padding: 6px;
    color:white;
    font-size: 0.95em;
}

.bgGGray{
    background-color: #fff7d9;
}